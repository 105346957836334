<div class="content search-section">
  <div class="header-container">
    <div class="flex-grid-2">
      <div class="col-1">
        <input type="text" placeholder='{{ "common.search" | translate }}' class="mdc-text-field__input" aria-labelledby="my-label-id">
      </div>
      <div class="col-2">
        <button mat-icon-button id="searchBtn" (click)="closeSearch()" class="search-close-btn">
          <mat-icon id="serachCloseIcn" class="search-close-icon" matTooltip="{{ 'common.close' | translate}}">close
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>