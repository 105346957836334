import { Injectable } from '@angular/core';
import { ExcelExportService } from './excel-export.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportHelperService {
  constructor(private excelExportService: ExcelExportService) {}

  public exportToExcel(data: any) {
    const jsonReqModel = {
      Header: {
        LeftHeader: [],
        RightHeader: [],
      },
      ColumnHeaders: [],
      RowValues: [],
      options: [],
    };

    Object.keys(data[0]).forEach((element: any) => {
      (jsonReqModel.ColumnHeaders as any).push({
        FieldName: element,
        Value: element,
      });
    });

    jsonReqModel.RowValues = data;

    this.excelExportService.excelGenerateToken(jsonReqModel).subscribe({
      next: (d) => {
        window.location.href = environment.base_url + 'excel/' + d.url;
      },
    });
  }

  public exportManagementProjectToExcel(data: any) {
    const jsonReqModel = {
      Header: {
        LeftHeader: [],
        RightHeader: [],
      },
      ColumnHeaders: [],
      RowValues: [],
      options: [],
    };

    Object.keys(data[0]).forEach((element: any) => {
      (jsonReqModel.ColumnHeaders as any).push({
        FieldName: element,
        Value: element,
      });
    });

    jsonReqModel.RowValues = data;

    this.excelExportService.excelGenerateToken(jsonReqModel).subscribe({
      next: (d) => {
        window.location.href = environment.base_url + 'excel/' + d.url;
      },
    });
  }

  public exportProgressProjectToExcel(data: any) {
    const jsonReqModel = {
      Header: {
        LeftHeader: [],
        RightHeader: [],
      },
      ColumnHeaders: [],
      RowValues: [],
      options: [],
    };

    Object.keys(data[0]).forEach((element: any) => {
      (jsonReqModel.ColumnHeaders as any).push({
        FieldName: element,
        Value: element,
      });
    });

    jsonReqModel.RowValues = data;

    this.excelExportService.excelGenerateToken(jsonReqModel).subscribe({
      next: (d) => {
        window.location.href = environment.base_url + 'excel/' + d.url;
      },
    });
  }

  public exportGridToExcel(data: any, COLUMNS: any) {
    const jsonReqModel = {
      Header: {
        LeftHeader: [],
        RightHeader: [],
      },
      ColumnHeaders: [],
      RowValues: [],
      options: [],
    };

    COLUMNS.forEach((element: any) => {
      (jsonReqModel.ColumnHeaders as any).push({
        FieldName: element.key,
        Value: element.header,
      });
    });
    
    jsonReqModel.RowValues = data;

    this.excelExportService.excelGenerateToken(jsonReqModel).subscribe({
      next: (d) => {
        window.location.href = environment.base_url + 'excel/' + d.url;
      },
    });
  }
}
