import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserListComponent } from 'src/app/core/modules/user/user-list/user-list.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { RevitService } from 'src/app/core/services/revit.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { Project } from '../../models/mbr-project.model';

@Component({
  selector: 'app-edit-revit-project-details-popup',
  templateUrl: './edit-revit-project-details-popup.component.html',
  styleUrls: ['./edit-revit-project-details-popup.component.scss']
})
export class EditRevitProjectDetailsPopupComponent implements OnInit {

  project !: Project;
  modifyProjectForm !: UntypedFormGroup;
  
  constructor(public dialogRef: MatDialogRef<UserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Project,
    private formBuilder: UntypedFormBuilder,
    private revitService: RevitService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private syncService: SyncService,) { }

  ngOnInit(): void {
    this.project = this.data;
    this.loadForm();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadForm(){
    this.modifyProjectForm = this.formBuilder.group({
      mbeProjectId: [this.project.mbeProjectId, [Validators.required]],
      centralFile: [this.project.centralFile, [Validators.required]],
    });
  }

  updateProject(project: any) {
    let modifiedProject = this.project;
    modifiedProject.centralFile = project.centralFile;
    modifiedProject.mbeProjectId = project.mbeProjectId;

    this.revitService.updateProject(modifiedProject).subscribe({
      next: async (sucess) => {
        if (sucess) {
          // sync start
          this.syncService.syncRevitAndIfc().subscribe({
            next: async (sucess) => {
              this.dialogRef.close(true);
            },
            error: (error) => {
              this.notificationService.showError(
                this.translate.instant('common.sync_error'),
                this.translate.instant('modules.user')
              );
            },
          });
          // sync end
          this.notificationService.showSuccess(
            this.translate.instant('common.success'),
            this.translate.instant('modules.user')
          );
        } else {
          this.notificationService.showError(
            this.translate.instant('common.error'),
            this.translate.instant('modules.revit')
          );
        }
      },
      error: (error) => {
        this.notificationService.showError(
          this.translate.instant('common.error'),
          this.translate.instant('modules.revit')
        );
      },
    });
  }

}
