<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{title}}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="form" (ngSubmit)="onClickSubmit()">
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.organizationNumber" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input type="text" maxlength="9" matInput name="organizationNumber"
                        formControlName="organizationNumber"
                        placeholder='{{ "form_popups.labels.organizationNumber" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-btn">
                <button type="submit" mat-raised-button class="mat-confirm-btn" [disabled]="form.invalid" [mat-dialog-close]="true">{{
                    "form_popups.buttons.confrim"
                    | translate }}</button>
                <button mat-raised-button class="mat-confirm-btn" [mat-dialog-close]="false">{{
                    "form_popups.buttons.close"
                    | translate }}</button>
            </div>
        </div>
    </form>
</div>