<div mat-dialog-title id="listPopupHeader">
    <div fxLayout="row wrap">
        <div fxFlex=75% fxLayoutAlign="start">
            <h1><b>{{data.title}}</b></h1>
        </div>
        <div fxFlex=25% fxLayoutAlign="end">
            <span>
                <mat-icon (click)="onClose()">close</mat-icon>
            </span>
        </div>
    </div>
</div>

<div mat-dialog-content>
    <table class="report-value-change-table">
        <tbody>
            <tr *ngFor="let val of data.content" class="info-item-{{val.label}}">
                <td class="td-label">{{ val.label }}</td>

                <td>
                    <div *ngIf="val.value && (val.value | typeof) === 'object'; else showStringTemplate">
                        <div *ngFor="let item of val.value | keyvalue" class="obj-value-item">
                            <span><b>{{item.key}}:</b> &nbsp;</span>
                            <span>{{item.value}}</span>
                        </div>
                    </div>

                    <ng-template #showStringTemplate>
                        {{val.value}}
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</div>