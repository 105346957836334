<mat-card class="common-dashboard-card without-progress-card" [ngClass]="{'inactive-tile': inactive === true, 'height-auto-tile': makeHeightAuto === true}">
    <mat-card-title class="title">{{title}}</mat-card-title>
    <mat-card-subtitle class="subtitle-wrapper" *ngIf="count || subTitle">
        <div class="subtitle" *ngIf="subTitle">
            {{subTitle}}
        </div>
        <div class="count" *ngIf="count">
            {{count}}
        </div>
    </mat-card-subtitle>
    <mat-card-content class="content-wrapper">
        <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
        <div *ngIf="infoList && infoList.length > 0">
            <div *ngFor="let infoListItem of infoList" class="info info-item-{{infoListItem.info}}">
                <span class="info-text">{{infoListItem.info}}</span>
                <span class="info-value">
                    <a *ngIf="infoListItem.link; else noLink" href="{{infoListItem.link}}">{{infoListItem.value}}</a>
                    <ng-template #noLink>{{infoListItem.value}}</ng-template>
                </span>
            </div>
        </div>

        <div *ngIf="logList && logList.length > 0">
            <div class="logs logs-headers">
                <span class="log-text">{{logHeaderOne}}</span>
                <span class="log-value">{{logHeaderTwo}}</span>
            </div>

            <div class="logs" *ngFor="let logListItem of logList">
                <span class="log-text">{{logListItem.info}}</span>
                <span class="log-value">{{logListItem.value}}</span>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions class="action-wrapper" align="end">
        <a class="pointer-link" *ngIf="isPopupTile" (click)="openListPopup()">
            {{ "common.more_details" | translate }}
            <span class="material-icons">
                chevron_right
            </span>
        </a>
        <a href="{{actionLink}}" *ngIf="!isPopupTile" [ngStyle]="{'pointer-events': (disabled == true) ? 'none' : 'auto' }">
            {{ "common.more_details" | translate }}
            <span class="material-icons">
                chevron_right
            </span>
        </a>
    </mat-card-actions>
</mat-card>