import { Injectable } from '@angular/core';
import { AuthService } from '../auth-service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGurdService {

  constructor(private authService: AuthService) { }

  canActivate() {
    if (this.authService.isAuthenticated()) { return true; }
    this.authService.login();
    return false;
  }

}
