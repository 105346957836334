import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Department } from 'src/app/shared/models/department.model';
import { StatusMessageService } from './status-message.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyUser } from 'src/app/shared/models/User.model';
import { EngineeringProjectTile } from 'src/app/shared/models/engineering-project-tile.model';
import { ProgressProjectUserType } from 'src/app/shared/models/progress-project-user-type.model';
import { DepartmentDocumentCount } from 'src/app/shared/models/department-document.model';
import { DepartmentDocument } from 'src/app/shared/models/department-document.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService
  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(
      error || this.translate.instant('status_message.common.server_error')
    );
  }

  public getDepartments(companyId: string): Observable<Department[]> {
    return this.httpService
      .getObject<Department[]>(
        'company/' + companyId + '/departments'
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.department_serive.load_department_message_fail'
            )
          )
        )
      );
  }

  public getDepartmentByCompanyId(
    companyId: string,
    departmentId: string
  ): Observable<Department> {
    return this.httpService
      .getObject<Department>(
        `company/${companyId}/departments/${departmentId}`
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.department_serive.load_department_message_fail'
            )
          )
        )
      );
  }

  public getDepartmentUsersByCompanyId(
    companyId: string,
    departmentId: string
  ): Observable<CompanyUser[]> {
    return this.httpService
      .getObject<CompanyUser[]>(
        `company/${companyId}/departments/${departmentId}/users`
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.department_serive.load_department_message_fail'
            )
          )
        )
      );
  }

  getEnginerringProjectsInfo(companyId: string, departmentId: string): Observable<EngineeringProjectTile> {
    return this.httpService.getObject<EngineeringProjectTile>(`company/${companyId}/departments/${departmentId}/mbe/projects`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companies_message_fail"))));
  }

  public getProgressProjectUserTypeByDepartmentId(companyId: string, departmentId: string): Observable<ProgressProjectUserType> {
    return this.httpService.getObject<ProgressProjectUserType>("mbp/projects/projectTypeCountByDepartmentId/company/" + companyId + "/department/" + departmentId).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public addDepartment(companyId: String, department: Department): Observable<Department> {
    return this.httpService.sendObjects<Department>(`company/${companyId}/departments/add`, department).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public updateDepartment(companyId: String, department: Department): Observable<Department> {
    return this.httpService.updateObjects<Department>(`company/${companyId}/departments/${department.id}`, department).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public getDepartmentDocumentCount(companyId: string, departmentId: string): Observable<DepartmentDocumentCount> {
    return this.httpService.getObject<DepartmentDocumentCount>(`company/${companyId}/department/${departmentId}/documentCount`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }
  
  public getAllDepartmentDocuments(companyId: string, departmentId: string): Observable<DepartmentDocument[]> {
    return this.httpService.getObject<DepartmentDocument[]>(`company/${companyId}/department/${departmentId}/document`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_company_documents_message_fail"))));
  }

  public createDepartmentDocument(departmentDocument: FormData): Observable<DepartmentDocument> {
    return this.httpService.sendObjects("company/department/document", departmentDocument).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.create_company_document_company_message_fail"))));
  }
  
  public deleteDepartmentDocument(documentId: string): Observable<boolean> {
    return this.httpService.deleteObject<boolean>(`company/department/${documentId}/document`).
    pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.delete_company_document_company_message_fail"))));
  }
  
  public downloadDepartmentDocument(documentId: any) {
    return this.httpService.getFileOutput(`company/department/document/${documentId}/download`).
    pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.common.download_company_document_message_fail"))));
  }
}
