import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tile-with-progress',
  templateUrl: './tile-with-progress.component.html',
  styleUrls: ['./tile-with-progress.component.scss']
})
export class TileWithProgressComponent implements OnInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() count: number = 0;
  @Input() actionLink: string = '';
  @Input() progressList?: any[];
  @Input() inactive?:boolean = false;
  @Input() disabled?:boolean = false;

  public isLoadingTiles : boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
