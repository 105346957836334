import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './http.service';
import { StatusMessageService } from './status-message.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class QueryService {
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService
  ) {}

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(
      error || this.translate.instant('status_message.common.server_error')
    );
  }

  public getUserAddedQuery(fromDate: any, toDate: any, userLogEvent: any) {
    return this.httpService
      .getObject<any[]>(
        `MBBOMBEUserLog/query/users?fromDate=${fromDate}&toDate=${toDate}&userLogEvent=${userLogEvent}`
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.project_service.load_projects_message_fail'
            )
          )
        )
      );
  }

  public getManagementProjectQuery(fromDate: any, toDate: any) {
    return this.httpService
      .getObject<any[]>(
        `query/mbmProjects?fromDate=${fromDate}&toDate=${toDate}`
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.project_service.load_projects_message_fail'
            )
          )
        )
      );
  }

  public getProgressProjectQuery(fromDate: any, toDate: any) {
    return this.httpService
      .getObject<any[]>(
        `query/mbpprojects?fromDate=${fromDate}&toDate=${toDate}`
      )
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.project_service.load_projects_message_fail'
            )
          )
        )
      );
  }

  public getUserActivityQuery(userIds: any[]) {
    return this.httpService
      .sendObjects<any[]>(`query/users/activity`, userIds)
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.project_service.load_projects_message_fail'
            )
          )
        )
      );
  }
}
