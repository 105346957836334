import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserListComponent } from 'src/app/core/modules/user/user-list/user-list.component';
import { AuthService } from 'src/app/core/services/auth-service/auth.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { User } from '../../models/User.model';

@Component({
  selector: 'reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss']
})
export class ResetUserPasswordComponent implements OnInit {

  form !: UntypedFormGroup;
  userData !: User;
  public isLoadingTiles : boolean = true;

  constructor(
    public dialogRef: MatDialogRef<UserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private authService: AuthService) {
    
   }

   ngOnInit(): void {
    this.userData = this.data;
    this.form = this.formBuilder.group({
      email: new UntypedFormControl(this.userData.email),
      password: new UntypedFormControl(""),
    });
  }

  onClickSubmit(): void {
    let formValues = this.form.value;

    let loggedInProfile = this.authService.getUserDetails().profile;

    formValues.superUserId = loggedInProfile.id;
    this.userService.resetPassword(this.form.value).subscribe({
      next: (data: any) => {
        
        this.notificationService.showSuccess(this.translate.instant("common.success"), this.translate.instant("modules.user"));
        this.dialogRef.close(true);
      }
      ,error: (err: any) => {
        this.notificationService.showError(this.translate.instant("common.error"), this.translate.instant("modules.user"));
      }
    })

    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
