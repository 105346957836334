<div class="second-header">
    <div class="header-container">
        <div class="flex-grid">
            <div class="col-1">
                <h2>
                    <span><strong> {{ pageHeader }}</strong></span>
                    <span
                        *ngIf="dataContext.pageNavigationguide?.name && (dataContext.navigationBy == 1 || dataContext.navigationBy == 2 || dataContext.navigationBy == 3)">
                        <small> | {{ dataContext.pageNavigationguide.name }}</small>
                    </span>
                </h2>
            </div>
            <div class="col-2">
                <div class="date-column-inner">
                    <span class="updated-date">
                        {{ "common.last_update" | translate }} - {{updatedDate | datepipe :dateType}}
                    </span>

                    <button mat-icon-button (click)="openSearch()">
                        <mat-icon matTooltip="{{ 'common.search' | translate}}">search</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-search-section *ngIf="dataContext.isOpenSearch" (closeSearchSection)="closeSearch()"></app-search-section>
<app-top-filter *ngIf="dataContext.isOpenTopFilter"></app-top-filter>