import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";
import { DateTimeType } from '../enums/date-time-type.enum';

@Pipe({
  name: 'datepipe'
})
export class DatePipe implements PipeTransform {

  transform(value: any, type: DateTimeType): unknown {
    if(value){
      if(moment(value).isValid()){
        if(type == DateTimeType.DateTime){
          return moment(value).format("HH:mm"+ " | "+"DD.MM.YYYY");
        }else{
          return moment(value).format("DD.MM.YYYY");
        }
      }else{
        return value;
      }
    }else{
      return null;
    }
  }

}
