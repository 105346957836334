<div mat-dialog-title id="listPopupHeader" style="display: flex; flex-direction: column; gap: 10px;">
  <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
    <div style="flex: 75%;">
      <h1><b>{{data.title}}</b></h1>
    </div>
    <div style="flex: 25%; display: flex; justify-content: flex-end;">
      <span>
        <mat-icon (click)="onClose()">close</mat-icon>
      </span>
    </div>
  </div>
</div>
<div mat-dialog-content>
  <table id="listPopupTable">
    <tr *ngFor="let val of data.content" class="info-item-{{val.label}}" style="display: flex;">
      <td class="td-label" style="flex: 1;">{{ val.label }}</td>
      <td class="td-value" style="flex: 2;">
        <a *ngIf="val.link; else noLink" href="{{val.link}}">{{val.value}}</a>
        <ng-template #noLink>{{val.value}}</ng-template>
      </td>
    </tr>
  </table>
</div>