import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Injectable({
    providedIn: 'root',
})

export class LoaderService {

    isLoading = new Subject<boolean>();
    loadState = new BehaviorSubject(false);
    
    constructor() {
    }

    show() {
        this.loadState.next(true);
    }

    hide() {
        this.loadState.next(false);
    }



}
