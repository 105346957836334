import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IfcProjectsComponent } from 'src/app/core/modules/ifc/ifc-projects/ifc-projects.component';
import { IfcService } from 'src/app/core/services/ifc.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { IfcProject } from '../../models/ifc-project.model';

@Component({
  selector: 'app-edit-ifc-project-details-popup',
  templateUrl: './edit-ifc-project-details-popup.component.html',
  styleUrls: ['./edit-ifc-project-details-popup.component.scss'],
})
export class EditIfcProjectDetailsPopupComponent implements OnInit {
  form!: UntypedFormGroup;
  projectData!: IfcProject;

  constructor(
    public dialogRef: MatDialogRef<IfcProjectsComponent>,
    public formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private ifcService: IfcService,
    private syncService: SyncService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: IfcProject
  ) {}

  ngOnInit(): void {
    this.projectData = this.data;
    this.loadFormData();
  }

  loadFormData() {
    this.form = this.formBuilder.group({
      id: new UntypedFormControl({ value: this.projectData.id, disabled: true }),
      mbeProjectId: new UntypedFormControl(this.projectData.mbeProjectId),
    });
  }

  onClickSubmit() {
    if (this.form.valid) {
      this.projectData.mbeProjectId = this.form.get('mbeProjectId')?.value;

      this.ifcService.updateProject(this.projectData).subscribe({
        next: async (sucess) => {
          if (sucess) {
            // sync start
            this.syncService.syncRevitAndIfc().subscribe({
              next: async (sucess) => {
                this.dialogRef.close();
              },
              error: (error) => {
                this.notificationService.showError(
                  this.translate.instant('common.sync_error'),
                  this.translate.instant('modules.ifc')
                );
              },
            });
            // sync end
            this.notificationService.showSuccess(
              this.translate.instant('common.success'),
              this.translate.instant('modules.ifc')
            );
          } else {
            this.notificationService.showError(
              this.translate.instant('common.error'),
              this.translate.instant('modules.ifc')
            );
          }
        },
        error: (error) => {
          this.notificationService.showError(
            this.translate.instant('common.error'),
            this.translate.instant('modules.ifc')
          );
        },
      });
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
