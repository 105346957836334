import { DatePipe } from '@angular/common';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ManagementProjectListComponent } from 'src/app/core/modules/management/management-project-list/management-project-list.component';
import { ColumnDataType, SortDirection } from '../../enums/column-data-type.enum';
import * as moment from "moment";

@Component({
  selector: 'app-management-workorder-popup',
  templateUrl: './management-workorder-popup.component.html',
  styleUrls: ['./management-workorder-popup.component.scss']
})
export class ManagementWorkorderPopupComponent implements OnInit {

  workorders: any;
  workorderList: any;
  isLoadingTiles : boolean = false;
  mbeGrid : any;
  userId!: string;

  readonly workorderGridColumns = [
    {
      key: 'isPreviewOnly', 
      header: this.translate.instant("common.status"),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '40%'
      },
    },
    {
      key: 'createdDate', 
      header: this.translate.instant("common.created_date"),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '40%'
      },
      originalKey: 'createdDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'endDate', 
      header: this.translate.instant("common.end_date"),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '40%'
      },
      originalKey: 'endDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'modifiedDate', 
      header: this.translate.instant("common.modified_date"),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '40%'
      },
      originalKey: 'modifiedDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    }
  ];

  constructor(private translate: TranslateService,
    public dialogRef: MatDialogRef<ManagementProjectListComponent>,
    private router: Router,
    private ngZone: NgZone,
    public datepipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

    ngOnInit(): void {
      this.assignData() ;
    }
  
    onClose(): void {
      this.dialogRef.close();
    }

    private async assignData() {
      this.workorders = this.data;
        this.workorderList = this.workorders.map((s: any) => {
          s['isPreviewOnly'] = s.isPreviewOnly
            ? this.translate.instant('common.inactive')
            : this.translate.instant('common.active');

          return {
            selected: false,
            object: s,
          };
        });

      this.mbeGrid = document.querySelector("mbe-grid#workorderList");
      await this.mbeGrid["updateDataAsync"](this.workorderList);
    }

    dateFormatterFn(date: string) {
      if(date){
        return moment(date).format("DD.MM.YYYY");
      }else{
        return "";
      }
      
    }

}
