<div class="content" class="top-filter">
  <div class="header-container">
    <div class="flex-grid">
      <div class="col-1">
        <form class="filter-form" [formGroup]="filterForm" autocomplete="off">
          <div class="close-for-mobile">
            <button mat-icon-button (click)="closeFilter()" class="search-close-btn">
              <mat-icon class="search-close-icon" matTooltip="{{ 'common.close' | translate}}">
                close
              </mat-icon>
            </button>
          </div>

          <a class="form-type-link" color="primary" (click)="openAdvanceFilter()" *ngIf="!isOpenAdvanceFilter">
            {{'filter.advance_filter' | translate}}
          </a>
          <a class="form-type-link" color="primary" (click)="openBasicFilter()" *ngIf="isOpenAdvanceFilter">
            {{'filter.basic_filter' | translate}}
          </a>

          <div class="form-field-wrap" *ngIf="!isOpenAdvanceFilter">
            <mat-form-field class="inline">
              <mat-label>{{ 'filter.date_range' | translate}}</mat-label>
              <mat-select name="dateRange" formControlName="dateRange">
                <mat-option *ngFor="let item of filterBy" [value]="item.value">{{item.viewValue}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-field-wrap" *ngIf="isOpenAdvanceFilter">
            <mat-form-field>
              <mat-label>{{ 'filter.date_range' | translate}}</mat-label>
              <mat-date-range-input [rangePicker]="picker" [max]="maxDateForDatePicker">
                <input matStartDate placeholder="{{ 'filter.from_date' | translate}}" formControlName="fromDate">
                <input matEndDate placeholder="{{ 'filter.to_date' | translate}}" formControlName="toDate">
              </mat-date-range-input>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>

          <button mat-button color="primary" class="mat-form-save-btn" [disabled]="filterForm.invalid"
            (click)="goToReportsPageWithFormData(filterForm.value)" *ngIf="!isReportsPage">
            {{ 'filter.view_reports' | translate}}
          </button>

          <button mat-button color="primary" class="mat-form-save-btn" [disabled]="filterForm.invalid"
            (click)="sendFormData(filterForm.value)" *ngIf="isReportsPage">
            {{ 'filter.apply' | translate}}
          </button>
        </form>
      </div>
      <div class="col-2">
        <div class="close-for-large"><button mat-icon-button (click)="closeFilter()" class="search-close-btn">
            <mat-icon class="search-close-icon" matTooltip="{{ 'common.close' | translate}}">
              close
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>