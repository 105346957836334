import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserListComponent } from 'src/app/core/modules/user/user-list/user-list.component';
import { ListPopupData } from '../../models/popup-detail.model';

@Component({
  selector: 'app-list-popup',
  templateUrl: './list-popup.component.html',
  styleUrls: ['./list-popup.component.scss']
})
export class ListPopupComponent implements OnInit {

  title : string = "";
  
  constructor(public dialogRef: MatDialogRef<UserListComponent>,@Inject(MAT_DIALOG_DATA) public data: ListPopupData) 
  { }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.title = this.data.title;
  }

}
