import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataContextService } from 'src/app/core/services/data-context.service';

@Component({
  selector: 'app-tripletex-confirm-popup',
  templateUrl: './tripletex-confirm-popup.component.html',
  styleUrls: ['./tripletex-confirm-popup.component.scss'],
})
export class TripletexConfirmPopupComponent implements OnInit {
  title!: string;
  message!: string;
  form!: UntypedFormGroup;
  numberInput!: string;
  myNumberProperty!: number;

  constructor(
    public dialogRef: MatDialogRef<TripletexConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TripletexConfirmPopupComponent,
    public formBuilder: UntypedFormBuilder,
    public dataContext: DataContextService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.title = this.data.title;
    this.message = this.data.message;
    this.form = this.formBuilder.group({
      organizationNumber: new UntypedFormControl(''),
    });
  }

  onClickSubmit() {
    if (this.form.valid) {
      this.dataContext.organizationNumber =
        this.form.get('organizationNumber')?.value;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
