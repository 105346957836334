export enum ColumnDataType {
    DATETIME = 1,
    STRINGNUMBER = 2,
    BOOLEAN = 3,
    DROPDOWN = 4,
    BUTTON = 5,
    BUTTONS_GROUP = 6,
}

export enum SortDirection{
    NONE = 1,
    ASC = 2,
    DESC = 3
}