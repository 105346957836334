export class Language {
    languageCode:string;
    name: string;
    languageId:LanguageEnum;

    constructor() {
        this.languageCode="";
        this.name = "";
        this.languageId = LanguageEnum.Norsk
    }
}

export enum LanguageEnum {
    Norsk = 1,
    Svensk = 2,
    English = 3,
    Dansk = 4
}