import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IfcProject } from 'src/app/shared/models/ifc-project.model';
import { ProjectCount } from 'src/app/shared/models/mbengineering-project.model';
import { HttpService } from './http.service';
import { StatusMessageService } from './status-message.service';

@Injectable({
  providedIn: 'root',
})
export class IfcService {
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService
  ) {}

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(
      error || this.translate.instant('status_message.common.server_error')
    );
  }

  public getIfcProjectCounts(): Observable<ProjectCount> {
    return this.httpService
      .getObject<ProjectCount>('ifc/projects/count')
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.ifc_project_listing.load_project_count_message_fail'
            )
          )
        )
      );
  }

  public getIfcProjects(): Observable<IfcProject[]> {
    return this.httpService
      .getObject<IfcProject[]>('ifc/projects')
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.ifc_project_listing.load_projects_message_fail'
            )
          )
        )
      );
  }

  public updateProject(project: IfcProject): Observable<boolean> {
    return this.httpService
      .sendObjects<IfcProject>('ifc/project/update', project)
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.ifc_project_listing.update_project_message_fail'
            )
          )
        )
      );
  }

  public removeProjectAndData(ifcId: String): Observable<boolean> {
    return this.httpService
      .deleteObject<any>(`ifc/project/${ifcId}`)
      .pipe(
        catchError((error) =>
          this.handleError(
            error,
            this.translate.instant(
              'status_message.ifc_project_listing.delete_project_message_fail'
            )
          )
        )
      );
  }
}
