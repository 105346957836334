
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { HttpClient } from "@angular/common/http";
import { catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from './loader.service';

@Injectable()
export class HttpService {
  private baseUrl!: string;
  private jsonToExcelUrl!: string;
  private totalRequests: number = 0;
  constructor(
    private http: HttpClient,
    private router: Router,
    private loaderService: LoaderService
  ) {
    if (environment) {
      this._setEndpoint();
    }
  }

  private _setEndpoint() {
    this.baseUrl = environment.base_url;
  }

  private setObserver() {
    if (this.totalRequests == 0) this.loaderService.hide();
    else this.loaderService.show();
  }

  private handleError(error: Response, router: Router) {
    return observableThrowError(error || '');
  }

  private onFinally(): void {
    this.afterRequest();
  }

  private afterRequest(): void {
    this.totalRequests -= 1;
    this.setObserver();
  }

  private beforeRequest(): void {
    this.totalRequests += 1;
    this.setObserver();
  }

  getBlob(url: string): Observable<any> {
    this.beforeRequest();
    return this.http
      .get(this.baseUrl + url, { responseType: 'blob' })
      .pipe(catchError((error) => this.handleError(error, this.router)))
      .pipe(finalize(() => this.onFinally()));
  }

  uploadBlob(url: string, file: any) {
    this.beforeRequest();

    let blob = new Blob(file, { type: 'application/octet-stream' });
    //blob["name"] = file.name;

    return this.http
      .post(this.baseUrl + url, blob)
      .pipe(finalize(() => this.onFinally()));
  }

  public getAccessToken<T>(userName: string, password: string): Observable<T> {
    var path = 'token';
    var body =
      'grant_type=password&username=' + userName + '&password=' + password;

    var accessToken = this.http
      .post<T>(this.baseUrl + path, body)
      // .map((response: Response) => <T>response.json() as T)
      .pipe(catchError((error) => this.handleError(error, this.router)))
      .pipe(finalize(() => this.onFinally()));

    return accessToken;
  }

  public getStringOutput<T>(path: string): Observable<T> {
    this.beforeRequest();
    return (
      this.http
        .get<T>(this.baseUrl + path)
        // .map((response: Response) => <T>response.json() as T)
        // .do(data => console.log('Retrieved string from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public getObjects<T>(path: string): Observable<T[]> {
    this.beforeRequest();
    return (
      this.http
        .get<T[]>(this.baseUrl + path)
        // .map((response: Response) => <T[]>response.json() as T[])
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public getObject<T>(path: string) {
    this.beforeRequest();
    return (
      this.http
        .get<T>(this.baseUrl + path)
        // .map((response: Response) => <T>response.json() as T)
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public sendObjects<T>(path: string, object: T) {
    this.beforeRequest();
    return (
      this.http
        .post<any>(this.baseUrl + path, object)
        // .map((response: Response) => <T>response.json() as T)
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public patchObjects<T>(path: string, object: T) {
    this.beforeRequest();
    return (
      this.http
        .patch<T>(this.baseUrl + path, object)
        // .map((response: Response) => <T>response.json() as T)
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public updateObjects<T>(path: string, object: T) {
    this.beforeRequest();
    return (
      this.http
        .put<T>(this.baseUrl + path, object)
        // .map((response: Response) => <T>response.json() as T)
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public deleteObject<T>(path: string) {
    this.beforeRequest();
    return (
      this.http
        .delete<any>(this.baseUrl + path)
        // .map((response: Response) => <T>response.json() as T)
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public downloadExcelFile(url: string) {
    //window.location.href = url + '/as/' + this.dataContext.currentProject.Name.replace('.', '_');
  }

  public sendObjects_excel<T>(path: string, object: T) {
    this.beforeRequest();
    return (
      this.http
        .post<T>(this.baseUrl + path, object)
        // .map((response: Response) => <T>response.json())
        // .do(data => console.log('Retrieved data from: ' + this.baseUrl + path))
        .pipe(catchError((error) => this.handleError(error, this.router)))
        .pipe(finalize(() => this.onFinally()))
    );
  }

  public getFileOutput(path: string){
      this.beforeRequest();
      return this.http.get(this.baseUrl + path, { responseType: 'blob' })
      .pipe(catchError((error) => this.handleError(error, this.router)))
      .pipe(finalize(() => this.onFinally()))
  }
}
