import {throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  constructor(
    private httpService: HttpService,
    ) { }

  excelGenerateToken(data: any){
    return this.httpService.sendObjects_excel(`excel/formatcontent/geturl`,data).pipe(catchError(error=> this.handleError(error, "Kunne ikke eksportere excel. Prøv på nytt.")));
  }

  private handleError(error: any, message: string) {
    return observableThrowError(error || "Server error");
  }
}