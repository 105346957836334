import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExcelExportHelperService } from 'src/app/core/services/excel-export-helper.service';
import { QueryService } from 'src/app/core/services/query.service';
import {
  ColumnDataType,
  SortDirection,
} from 'src/app/shared/enums/column-data-type.enum';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss'],
})
export class UserActivityComponent implements OnInit {
  pageHeader: string = this.translate.instant('modules.query');
  isLoadingTiles: boolean = false;

  userIds: any[] = [];
  queryResult: any[] = [];
  queryGridResult: any[] = [];
  mbeGrid: any;

  readonly COLUMNS = [
    {
      key: 'name',
      header: this.translate.instant('common.name'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'company',
      header: this.translate.instant('common.company'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'department',
      header: this.translate.instant('common.department'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'email',
      header: this.translate.instant('common.email'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'mobile',
      header: this.translate.instant('common.mobile'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbeAdmin',
      header: this.translate.instant('query.grid.mbe_admin'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbeWrite',
      header: this.translate.instant('query.grid.mbe_write'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbeRead',
      header: this.translate.instant('query.grid.mbe_read'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbpAdmin',
      header: this.translate.instant('query.grid.mbp_admin'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbpClient',
      header: this.translate.instant('query.grid.mbp_client'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbmAdmin',
      header: this.translate.instant('query.grid.mbm_admin'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
    {
      key: 'mbmClient',
      header: this.translate.instant('query.grid.mbm_client'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
      },
    },
  ];

  constructor(
    private translate: TranslateService,
    private queryService: QueryService,
    private router: Router,
    private excelExportHelperService: ExcelExportHelperService
  ) {
    this.userIds = this.router.getCurrentNavigation()!.extras!.state!.userIds;
  }

  ngOnInit(): void {
    this.queryService
      .getUserActivityQuery(this.userIds)
      .subscribe(async (data) => await this._assignData(data));
  }

  private async _assignData(data: any) {
    this.queryResult = data;
    this.queryGridResult = data.map((s: any) => {
      return {
        selected: false,
        object: s,
      };
    });

    this.mbeGrid = document.querySelector('mbe-grid#queryList');
  }

  onExcelExport() {
    this.excelExportHelperService.exportToExcel(this.queryResult);
  }
}
