import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SearchSectionComponent } from './components/search-section/search-section.component';
import { MaterialModule } from './material/material.module';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { ListPopupComponent } from './popups/list-popup/list-popup.component';
import { ModifyUserDetailsPopupComponent } from './popups/modify-user-details-popup/modify-user-details-popup.component';
import { AddToMailchimpPopupComponent } from './popups/add-to-mailchimp-popup/add-to-mailchimp-popup.component';
import { ActivateUserPopupComponent } from './popups/activate-user-popup/activate-user-popup.component';
import { EditIfcProjectDetailsPopupComponent } from './popups/edit-ifc-project-details-popup/edit-ifc-project-details-popup.component';
import { EditRevitProjectDetailsPopupComponent } from './popups/edit-revit-project-details-popup/edit-revit-project-details-popup.component';
import { UpdateCompanyPopupComponent } from './popups/update-company-popup/update-company-popup.component';
import { ConfirmPopupComponent } from './popups/confirm-popup/confirm-popup.component';
import { TileWithProgressComponent } from './components/tile-with-progress/tile-with-progress.component';
import { TileWithoutProgressComponent } from './components/tile-without-progress/tile-without-progress.component';
import { AuthCallbackComponent } from './components/auth-callback/auth-callback.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { DepartmentListPopupComponent } from './popups/department-list-popup/department-list-popup.component';
import { UserListPopupComponent } from './popups/user-list-popup/user-list-popup.component';
import { ManagementCompanyUserPopupComponent } from './popups/management-company-user-popup/management-company-user-popup.component';
import { ManagementWorkorderPopupComponent } from './popups/management-workorder-popup/management-workorder-popup.component';
import { ProgressTradeCompanyUserPopupComponent } from './popups/progress-trade-company-user-popup/progress-trade-company-user-popup.component';
import { DepartmentFormsPopupComponent } from './popups/department-forms-popup/department-forms-popup.component';
import { DatePipe } from './pipes/date.pipe';
import { TopFilterComponent } from './components/top-filter/top-filter.component';
import { ResetUserPasswordComponent } from './popups/reset-user-password/reset-user-password.component';
import { ReportValueChangePopupComponent } from './popups/report-value-change-popup/report-value-change-popup.component';
import { TypeofPipe } from './pipes/typeof.pipe';
import { TripletexConfirmPopupComponent } from './popups/tripletex-confirm-popup/tripletex-confirm-popup.component';

@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    SearchSectionComponent,
    ContentHeaderComponent,
    ListPopupComponent,
    ModifyUserDetailsPopupComponent,
    AddToMailchimpPopupComponent,
    ActivateUserPopupComponent,
    EditIfcProjectDetailsPopupComponent,
    EditRevitProjectDetailsPopupComponent,
    UpdateCompanyPopupComponent,
    ConfirmPopupComponent,
    TileWithProgressComponent,
    TileWithoutProgressComponent,
    AuthCallbackComponent,
    LoaderComponent,
    DepartmentListPopupComponent,
    UserListPopupComponent,
    ManagementCompanyUserPopupComponent,
    ManagementWorkorderPopupComponent,
    ProgressTradeCompanyUserPopupComponent,
    DepartmentFormsPopupComponent,
    DatePipe,
    TopFilterComponent,
    ResetUserPasswordComponent,
    ReportValueChangePopupComponent,
    TypeofPipe,
    TripletexConfirmPopupComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule, ReactiveFormsModule,
  ],
  exports:[
    SearchSectionComponent,
    MaterialModule,
    ContentHeaderComponent,
    TileWithProgressComponent,
    TileWithoutProgressComponent,
    AuthCallbackComponent,
    LoaderComponent,
    DatePipe,
    TopFilterComponent
  ]
})
export class SharedModule { }
