import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TopFilterService } from 'src/app/core/services/top-filter.service';
import { ReportFilter } from '../../models/report-filter.model';

@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.scss'],
})
export class TopFilterComponent implements OnInit {
  @Input() preSelectedDateRange?: number = 1;
  @Input() preSelectedFromDate?: string = '';
  @Input() preSelectedToDate?: string = '';
  @Input() isReportsPage?: boolean = false;
  @Input() isOpenAdvanceFilter?: boolean = false;
  @Output() filterFormSubmittedData = new EventEmitter<ReportFilter>();

  filterForm!: UntypedFormGroup;
  filterBy = [
    { value: 1, viewValue: 'Last week' },
    { value: 2, viewValue: 'Last two weeks' },
    { value: 3, viewValue: 'Last month' },
  ];
  maxDateForDatePicker: Date = new Date();

  constructor(
    private topFilterService: TopFilterService,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isOpenAdvanceFilter = this.preSelectedFromDate ? true : false;
    this.loadForm();
  }

  closeFilter() {
    this.topFilterService.closeTopFilter();
  }

  openAdvanceFilter() {
    this.isOpenAdvanceFilter = true;
    this.loadForm();
  }

  openBasicFilter() {
    this.isOpenAdvanceFilter = false;
    this.loadForm();
  }

  loadForm() {
    this.filterForm = this.formBuilder.group({
      dateRange: this.isOpenAdvanceFilter
        ? new UntypedFormControl(this.preSelectedDateRange)
        : new UntypedFormControl(this.preSelectedDateRange, [Validators.required]),
      fromDate: this.isOpenAdvanceFilter
        ? new UntypedFormControl(this.preSelectedFromDate, [Validators.required])
        : new UntypedFormControl(''),
      toDate: this.isOpenAdvanceFilter
        ? new UntypedFormControl(this.preSelectedToDate, [Validators.required])
        : new UntypedFormControl(''),
    });
  }

  goToReportsPageWithFormData(formData: any) {
    var filterDataToSend: ReportFilter = new ReportFilter();
    filterDataToSend.dateRange = formData.dateRange;
    filterDataToSend.fromDate = formData?.fromDate
    ? moment(formData?.fromDate).format("MM/DD/YYYY")
    : '';
    filterDataToSend.toDate = formData?.toDate
    ? moment(formData?.toDate).format("MM/DD/YYYY")
    : '';

    this.router.navigate(['report'], {
      queryParams: {
        originPage: this.topFilterService.getReportFilterOrigin().originPage,
        originPageId: this.topFilterService.getReportFilterOrigin().originPageId,
        parentId: this.topFilterService.getReportFilterOrigin().parentId,
        fromDate: filterDataToSend.fromDate,
        toDate: filterDataToSend.toDate,
        dateRange: filterDataToSend.dateRange,
      },
    });
  }

  sendFormData(formData: any) {
    var filterDataToSend: ReportFilter = new ReportFilter();
    filterDataToSend.dateRange = formData.dateRange;
    filterDataToSend.fromDate = formData?.fromDate
    ? moment(formData?.fromDate).format("MM/DD/YYYY")
    : '';
    filterDataToSend.toDate = formData?.toDate
    ? moment(formData?.toDate).format("MM/DD/YYYY")
    : '';

    this.filterFormSubmittedData.emit(filterDataToSend);
  }
}
