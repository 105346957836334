import { NavigateByType } from './page-navigation-guide.model';

export class ReportFilterWithPageOrigin {
  originPage!: NavigateByType;
  originPageId!: string;
  dateRange!: number;
  fromDate!: string;
  toDate!: string;
  parentId!: string;
}

export class ReportFilter {
  dateRange: number;
  fromDate!: string;
  toDate!: string;

  constructor() {
    this.dateRange = 0;
  }
}
