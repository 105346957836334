import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '../../models/User.model';
import { Company } from '../../models/company.model';
import { CompanyService } from 'src/app/core/services/company.service';
import { DepartmentService } from 'src/app/core/services/department.service';
import { Department } from '../../models/department.model';
import { LanguageEnum } from '../../models/language.model';
import { CodeCompany } from '../../models/code-company.model';
import { License } from '../../enums/role.enum';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { SyncService } from 'src/app/core/services/sync.service';
import { UserListComponent } from 'src/app/core/modules/user/user-list/user-list.component';

@Component({
  selector: 'app-modify-user-details-popup',
  templateUrl: './modify-user-details-popup.component.html',
  styleUrls: ['./modify-user-details-popup.component.scss']
})
export class ModifyUserDetailsPopupComponent implements OnInit {

  userData !: User;
  modifyUserForm !: UntypedFormGroup;
  companies !: Company[];
  departments!: Department[];
  codeCompanies!: CodeCompany[];
  license = License;
  enumKeys: any = [];
  language = LanguageEnum;
  languageKeys : any = [];
  mobilePrefixes = ['45', '46', '47', '94'];

  public isLoadingTiles : boolean = true;

  constructor(
    private companyService: CompanyService,
    private departmentService: DepartmentService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private syncService: SyncService,
    public dialogRef: MatDialogRef<UserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User
  ) {
    dialogRef.disableClose = true;
    this.enumKeys = Object.keys(this.license).filter(f => !isNaN(Number(f)));
    this.languageKeys = Object.keys(this.language).filter(f => !isNaN(Number(f)));
  }

  ngOnInit(): void {
    this.userData = this.data;
    this._loadCompanies();
    this._loadCodeCompanies();
    this._loadDepartmentsByCompany(this.userData.companyId);
    this.loadForm();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadForm() {
    this.modifyUserForm = this.formBuilder.group({
      name: [this.userData.name, [Validators.required]],
      email: [this.userData.email, [Validators.required, Validators.email]],
      mobile: [this.userData.mobile],
      company: [this.userData.companyId, [Validators.required]],
      department: [this.userData.departmentId],
      codeCompany: [this.userData.codeCompanyId, [Validators.required]],
      language: [this.userData.languageId, [Validators.required]],
      license: [this.userData.license, [Validators.required]],
      isActive: [this.userData.isActive],
      mobilePrefix: [this.userData.mobilePrefix]
    });
  }

  private _loadCompanies() {
    this.companyService.getAllCompanies().subscribe((data: Company[]) => this.companies = data)
  }

  onchangeCompany(e: any) {
    this.userData.companyId = e.value;
    this.userData.departmentId = ""
    this._loadDepartmentsByCompany(e.value);
  }

  private _loadDepartmentsByCompany(companyId: string) {
    if (companyId) {
      this.departmentService.getDepartments(companyId).subscribe((data: Department[]) => this.departments = data)
    }
  }

  onChangeDepartment(e: any) {
    this.userData.departmentId = e.value;
  }

  private _loadCodeCompanies() {
    this.companyService.getAllCodeCompanies().subscribe((data: CodeCompany[]) => this.codeCompanies = data);
  }

  updateUser(user: any) {
    let modifiedUser = this.setUser(user)
    this.userService.updateUser(modifiedUser).subscribe({
      next: async sucess => {
        if (sucess) {
          this.dialogRef.close(sucess);
          this.notificationService.showSuccess(this.translate.instant("common.success"), this.translate.instant("modules.user"));
        }
      },
      error: error => {
        this.notificationService.showError(this.translate.instant("common.error"), this.translate.instant("modules.pending_user"));
      }
    });
  }

  setUser(user: any) {
    let modifiedUser = new User();
    modifiedUser.id = this.userData.id;
    modifiedUser.name = user.name;
    modifiedUser.email = user.email;
    modifiedUser.mobile = user.mobile;
    modifiedUser.license = user.license;
    modifiedUser.languageId = user.language;
    modifiedUser.isActive = user.isActive;
    modifiedUser.companyId = this.userData.companyId;
    modifiedUser.departmentId = this.userData.departmentId;
    modifiedUser.codeCompanyId = user.codeCompany
    modifiedUser.mobilePrefix = this.userData.mobilePrefix;
    modifiedUser.isTwoFactorEnabled = this.userData.isTwoFactorEnabled;
    return modifiedUser;
  }

  onChangeMobilePrefix(e: any) {
    this.userData.mobilePrefix = e.value;
  }
}
