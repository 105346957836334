<div mat-dialog-title id="formPopupHeader">
  <div class="formPopupHeader-left">
    <b>{{ "form_popups.headers.workorder_popup" | translate }}</b>
  </div>
  <div class="formPopupHeader-right">
      <span>
          <mat-icon (click)="onClose()">close</mat-icon>
      </span>
  </div>
</div>
  <div mat-dialog-content>
    <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
    <mbe-grid id="workorderList" class="full-page-grid" [columns]="workorderGridColumns" [objects]="workorderList" [dateFormatter]="dateFormatterFn">
    </mbe-grid>
  </div>
  