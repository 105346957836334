export const environment = {
    production: false,
    staging: false,
    version: '',
    base_url: 'https://v2.api.dev.backofficemicrobuild.no/',
    jsonToExcel_url: 'https://jsontoexcel.devmicrobuild.no/',
    authSettings: {
      authority: 'https://identityserver.devmicrobuild.no/', 
      client_id: 'mb-backoffice-v2',
      redirect_uri: 'https://v2.dev.backofficemicrobuild.no/auth-callback',
      post_logout_redirect_uri: 'https://v2.dev.backofficemicrobuild.no/',
      scope: 'openid backoffice offline_access',
    },
    
}