export class PageNavigationGuide{
    public id : string;
    public name : string;
    public type !: NavigateByType
    constructor() {
        this.id = "";
        this.name = ""; 
    }
}

export enum NavigateByType{
    None = 0,
    User = 1,
	Company = 2,
	Department = 3,
    Dashboard = 4
}