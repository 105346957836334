import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth-service/auth.service';
import { DataContextService } from '../../services/data-context.service';
import { TopFilterService } from '../../services/top-filter.service';

@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.scss']
})
export class TopHeaderComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private topFilterService: TopFilterService,
    public dataContext: DataContextService,
    ) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }

  openFilter(){
    this.dataContext.isOpenSearch = false;
    this.topFilterService.openTopFilter();
  }
}
