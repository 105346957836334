import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyListComponent } from 'src/app/core/modules/company/company-list/company-list.component';
import { DepartmentService } from 'src/app/core/services/department.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { Department } from '../../models/department.model';

@Component({
  selector: 'app-department-forms-popup',
  templateUrl: './department-forms-popup.component.html',
  styleUrls: ['./department-forms-popup.component.scss']
})
export class DepartmentFormsPopupComponent implements OnInit {

  createDepartmentForm !: UntypedFormGroup;
  department !: Department;
  companyId: String = '';

  public isLoadingTiles : boolean = true;

  constructor(private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private syncService: SyncService,
    private departmentService: DepartmentService,
    public dialogRef: MatDialogRef<CompanyListComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.department = this.data?.department;
    this.companyId = this.data?.companyId;
    this.loadForm();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  loadForm(){
    this.createDepartmentForm = this.formBuilder.group({
      id: [this.department ? this.department.id : '',],
      name: [this.department ? this.department.name : '', [Validators.required]],
      adress1: [ this.department ? this.department.adress1 : ''],
      adress2: [this.department ? this.department.adress2 : ''],
      city: [this.department ? this.department.city : '', [Validators.required]],
      postNo: [this.department ? this.department.postNo : ''],
      phone1: [this.department ? this.department.phone1 : '', [Validators.required]]
    });
  }

  createDepartment(department: Department){

    if(department.id == null || department.id === ''){
      delete department.id;
      this.departmentService.addDepartment(this.companyId, department).subscribe({
        next: async sucess => {
          if (sucess) {
            this.syncService.SyncCompany().subscribe({
              next: async sucess =>{
                this.router.navigate(['./company/company-list'])
                .then(() => {
                  window.location.reload();
                });
                this.dialogRef.close(true);
              },
              error: error => {
                this.notificationService.showError(this.translate.instant("common.sync_error"), this.translate.instant("modules.company"));
              }
            });
            this.notificationService.showSuccess(this.translate.instant("common.success"), this.translate.instant("modules.company"));
          }
        },
        error: error => {
          this.notificationService.showError(this.translate.instant("common.error"), this.translate.instant("modules.company"));
        }
      });
    }
    else{
      this.departmentService.updateDepartment(this.companyId, department).subscribe({
        next: async sucess => {
          if (sucess) {
            this.syncService.SyncCompany().subscribe({
              next: async sucess =>{
                this.router.navigate(['./company/company-list'])
                .then(() => {
                  window.location.reload();
                });
                this.dialogRef.close(true);
              },
              error: error => {
                this.notificationService.showError(this.translate.instant("common.sync_error"), this.translate.instant("modules.company"));
              }
            });
            this.notificationService.showSuccess(this.translate.instant("common.success"), this.translate.instant("modules.company"));
          }
        },
        error: error => {
          this.notificationService.showError(this.translate.instant("common.error"), this.translate.instant("modules.company"));
        }
      });
    }
    
  }


}
