import { Component, Input, OnInit } from '@angular/core';
import { DataContextService } from 'src/app/core/services/data-context.service';
import { PageNavigationGuideService } from 'src/app/core/services/page-navigation-guide.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { DateTimeType } from '../../enums/date-time-type.enum';
import { NavigateByType, PageNavigationGuide } from '../../models/page-navigation-guide.model';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
})
export class ContentHeaderComponent implements OnInit {
  updatedDate!: string;
  @Input() pageHeader: string | undefined;
  dateType = DateTimeType.DateTime;

  constructor(private syncService: SyncService,
    public dataContext: DataContextService,
    private pageNavigationService: PageNavigationGuideService) { }

  ngOnInit(): void {
    this.getLastSyncDate();
    this.getPageNavigationGuide();
  }

  getLastSyncDate() {
    let lastSyncDate = this.syncService.getLastSyncDate();
    
    if(lastSyncDate === ''){
      this.syncService.lastSyncDate().subscribe({
        next: (data: any) => {
          if (data) {
            let date = new Date(data);
            let formattedDate = `${date.getUTCHours()}:${date.getUTCMinutes()}  ${date.getFullYear()}/${(date.getMonth() + 1)}/${date.getDate()}`;
            this.updatedDate = formattedDate;
            this.syncService.setLastSyncDate(formattedDate);
          }
        },
      });
    }
    else{
      this.updatedDate = lastSyncDate;
    }
    
  }

  openSearch() {
    this.dataContext.isOpenSearch = true;
    this.dataContext.isOpenTopFilter = false;
  }

  closeSearch() {
    this.dataContext.isOpenSearch = false;
  }

  getPageNavigationGuide() {
    if (this.dataContext.navigationBy == NavigateByType.User) {
      if(this.dataContext.pageNavigationguide?.id && this.dataContext.userId){
        if(this.dataContext.pageNavigationguide?.id != this.dataContext.userId){
          this.pageNavigationService.getPageNavigationGuideForUser(this.dataContext.navigationBy, this.dataContext.userId).subscribe({
            next: (data: PageNavigationGuide) => {
              if (data) {
                this.dataContext.pageNavigationguide = data;
              }
            },
          });
        }
      }else{
        this.pageNavigationService.getPageNavigationGuideForUser(this.dataContext.navigationBy, this.dataContext.userId).subscribe({
          next: (data: PageNavigationGuide) => {
            if (data) {
              this.dataContext.pageNavigationguide = data;
            }
          },
        });
      }
      
    }else if(this.dataContext.navigationBy == NavigateByType.Company){
      if(this.dataContext.pageNavigationguide?.id && this.dataContext.companyId){
        if(this.dataContext.pageNavigationguide?.id != this.dataContext.companyId){
          this.pageNavigationService.getPageNavigationGuideForCompany(this.dataContext.navigationBy, this.dataContext.companyId).subscribe({
            next: (data: PageNavigationGuide) => {
              if (data) {
                this.dataContext.pageNavigationguide = data;
              }
            },
          });
        }
      }else{
        this.pageNavigationService.getPageNavigationGuideForCompany(this.dataContext.navigationBy, this.dataContext.companyId).subscribe({
          next: (data: PageNavigationGuide) => {
            if (data) {
              this.dataContext.pageNavigationguide = data;
            }
          },
        });
      }
    }
    else if(this.dataContext.navigationBy == NavigateByType.Department){
      if(this.dataContext.pageNavigationguide?.id && this.dataContext.companyId && this.dataContext.departmentId){
        if(this.dataContext.pageNavigationguide?.id != this.dataContext.departmentId){
          this.pageNavigationService.getPageNavigationGuideForDepartment(this.dataContext.navigationBy, this.dataContext.companyId,this.dataContext.departmentId).subscribe({
            next: (data: PageNavigationGuide) => {
              if (data) {
                this.dataContext.pageNavigationguide = data;
              }
            },
          });
        }
      }else{
        this.pageNavigationService.getPageNavigationGuideForDepartment(this.dataContext.navigationBy, this.dataContext.companyId,this.dataContext.departmentId).subscribe({
          next: (data: PageNavigationGuide) => {
            if (data) {
              this.dataContext.pageNavigationguide = data;
            }
          },
        });
      }
    }
  }

}
