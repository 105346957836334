export class MailChimpUser {
    public firstName: string;
    public lastName: string;
    public email: string;

    constructor(){
        this.firstName = "";
        this.lastName = "";
        this.email = "";
    }
}
