export enum License {
	SuperUser = 1,
	ProducerAdmin = 2,
	User = 3
}

export enum Role {
	Admin = 1,
	ReadOnly = 2,
	ReadWrite = 3
}