export class PendingUser {
    public id : string;
    public name : string;
    public email : string;
    public password : string;
    public hashedPassword : string;
    public mobile : string;
    public microBuildId : number;
    public codeCompanyId : string;
    public languageId : string;
    public license : string;
    public isActive : boolean;
    public companyId : string;
    public departmentId : string;
    public companyname : string;
    public address : string;
    public postNo : string;
    public city : string;
    public mobilePrefix : string;
    public createdDate : string;

    constructor(){
        this.id = "";
        this.name = "";
        this.email = "";
        this.password = "";
        this.hashedPassword = "";
        this.mobile  = "";
        this.microBuildId = 0;
        this.codeCompanyId = "";
        this.languageId = "";
        this.license = "";
        this.isActive = false;
        this.companyId = "";
        this.departmentId = "";
        this.companyname = "";
        this.address = "";
        this.postNo = "";
        this.city = "";
        this.mobilePrefix = "";
        this.createdDate = "";
    }
}

export class PendingUserCount {
    public totalPendingUserCount : number;
    constructor(){
        this.totalPendingUserCount = 0
    }
}