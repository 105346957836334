<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.activate_user" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" (ngSubmit) = "onClickSubmit()" [formGroup]="form">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <a class="add-company-link" href="/company/company-list?openCreateCompany=true">{{ 'form_popups.headers.create_company' | translate}}</a>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.company" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select name="companyId" formControlName="companyId" placeholder='{{ "form_popups.placeholders.select_company" | translate }}' 
                    (selectionChange)="onchangeCompany($event)" required>
                        <mat-option *ngFor="let company of companies" [value]="company.id" >{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.department" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select name="departmentId" formControlName="departmentId" 
                placeholder='{{ "form_popups.placeholders.select_department" | translate }}'>
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor="let department of departments" [value]="department.id" >{{department.name}}</mat-option>
                </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.code_company" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select name="codeCompanyId" formControlName="codeCompanyId" 
                    placeholder='{{ "form_popups.placeholders.select_code_company" | translate }}' required>
                        <mat-option *ngFor="let codeCompany of codeCompanies" [value]="codeCompany.id">{{codeCompany.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.language" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select name="languageId" formControlName="languageId" 
                    placeholder='{{ "form_popups.placeholders.select_language" | translate }}' required>
                        <mat-option *ngFor="let language of languages" [value]="language.languageId">{{language.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.license" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field" >
                    <mat-select name="license" formControlName="license" 
                    placeholder='{{ "form_popups.placeholders.select_license" | translate }}' required>
                        <mat-option *ngFor="let name of enumKeys"  [value]="name"> {{license[name]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" type="submit" [disabled]="form.invalid" mat-raised-button class="mat-form-save-btn">{{ "form_popups.buttons.save" | translate }}</button>
            </div>
        </div>
    </form>
</div>
