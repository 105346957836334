import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Sync, SyncDetail } from 'src/app/shared/models/sync.model';
import { HttpService } from './http.service';
import { StatusMessageService } from './status-message.service';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  syncDate: string = '';
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService
  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(
      error || this.translate.instant('status_message.common.server_error')
    );
  }

  public sync(): Observable<any> {
    return this.httpService.sendObjects('sync', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('sync_logs.sync_failed_message'))));
  }

  public SyncUser(): Observable<any> {
    return this.httpService.sendObjects('sync/user', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.user_service.create_user_message_fail'))));

  }

  public SyncUpdateUser(): Observable<any> {
    return this.httpService.sendObjects('sync/updateuser', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.user_service.create_user_message_fail'))));
  }

  public SyncSavePendingUser(): Observable<any> {
    return this.httpService.sendObjects('sync/savependinguser', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_user_message_fail'))));
  }

  public SyncRemovePendingUser(): Observable<any> {
    return this.httpService.sendObjects('sync/removependinguser', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_pending_user_message_fail'))));
  }

  public SyncCompany(): Observable<any> {
    return this.httpService.sendObjects('sync/company', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_company_message_fail'))));
  }

  public lastSyncDate(): Observable<any> {
    return this.httpService.getObject('sync/lastUpdatedDate').
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_updated_date_message_fail'))));
  }

  public setLastSyncDate(date: string){
    this.syncDate = date;
  }

  public getLastSyncDate() {
    return this.syncDate;
  }

  public syncRevitAndIfc(): Observable<any> {
    return this.httpService.sendObjects("sync/revitAndIfc", null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_company_message_fail'))));
  }

  public getAllSyncs(): Observable<Sync[]> {
    return this.httpService.getObjects<Sync>('allSyncs').
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.get_all_syncs_message_fail'))));
  }

  public getSyncDetailsBySyncId(syncId: string): Observable<SyncDetail[]> {
    return this.httpService.getObjects<SyncDetail>(`sync/${syncId}/details`).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.get_sync_details_message_fail'))));

  }

  public getLastSync(): Observable<Sync> {
    return this.httpService.getObject<Sync>('sync/lastSync').
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.get_all_syncs_message_fail'))));

  }

  public SyncEngineeringProjects(): Observable<any> {
    return this.httpService.sendObjects('sync/engineering/projects', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_failed_message'))));

  }

  public SyncManagementProjects(): Observable<any> {
    return this.httpService.sendObjects('sync/management/projects', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_failed_message'))));

  }

  public SyncProgressProjects(): Observable<any> {
    return this.httpService.sendObjects('sync/progress/projects', null).
      pipe(catchError(error => this.handleError(error, this.translate.instant('status_message.sync_service.sync_failed_message'))));
  }
}
