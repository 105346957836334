<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.modify_user_info" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="modifyUserForm">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.name" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input formControlName="name" matInput placeholder='{{ "form_popups.placeholders.enter_name" | translate }}'>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.email" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input formControlName="email" matInput placeholder='{{ "form_popups.placeholders.enter_email" | translate }}' readonly>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.mobilePrefix" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="mobilePrefix" (selectionChange)="onChangeMobilePrefix($event)" placeholder='{{ "form_popups.placeholders.select_mobile_prefix" | translate }}'>
                        <mat-option *ngFor="let mobilePrefix of mobilePrefixes" [value]="mobilePrefix">+{{mobilePrefix}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.mobile" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input formControlName="mobile"  matInput placeholder='{{ "form_popups.placeholders.enter_mobile" | translate }}'>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.active_status" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="isActive" placeholder='{{ "form_popups.placeholders.select_is_active" | translate }}'>
                        <mat-option [value]="true">{{ "form_popups.labels.active" | translate }}</mat-option>
                        <mat-option [value]="false">{{ "form_popups.labels.inactive" | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.company" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="company"  (selectionChange)="onchangeCompany($event)" placeholder='{{ "form_popups.placeholders.select_company" | translate }}'>
                        <mat-option *ngFor="let company of companies" [value]="company.id">{{company.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.department" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="department" (selectionChange)="onChangeDepartment($event)" placeholder='{{ "form_popups.placeholders.select_department" | translate }}'>
                        <mat-option value=""></mat-option>
                        <mat-option *ngFor="let department of departments" [value]="department.id">{{department.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.code_company" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="codeCompany" placeholder='{{ "form_popups.placeholders.select_code_company" | translate }}'>
                        <mat-option *ngFor="let codeCompany of codeCompanies" [value]="codeCompany.id">{{codeCompany.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.language" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="language" placeholder='{{ "form_popups.placeholders.select_language" | translate }}'>
                        <mat-option *ngFor="let name of languageKeys"  [value]="name"> {{language[name]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.license" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <mat-select formControlName="license" placeholder='{{ "form_popups.placeholders.select_license" | translate }}'>
                        <mat-option *ngFor="let name of enumKeys"  [value]="name"> {{license[name]}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" [disabled]="modifyUserForm.invalid" mat-raised-button class="mat-form-save-btn" (click)="updateUser(modifyUserForm.value)">{{ "form_popups.buttons.save" | translate }}</button>
            </div>
        </div>
    </form>
</div>
