import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PendingUserListComponent } from 'src/app/core/modules/pending-user/pending-user-list/pending-user-list.component';
import { CompanyService } from 'src/app/core/services/company.service';
import { DepartmentService } from 'src/app/core/services/department.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { UserService } from 'src/app/core/services/user.service';
import { License } from '../../enums/role.enum';
import { CodeCompany } from '../../models/code-company.model';
import { Company } from '../../models/company.model';
import { Department } from '../../models/department.model';
import { Language } from '../../models/language.model';
import { MailChimpUser } from '../../models/mail-chimp-user.model';
import { PendingUser } from '../../models/pending-user.model';
import { User } from '../../models/User.model';
import { AddToMailchimpPopupComponent } from '../add-to-mailchimp-popup/add-to-mailchimp-popup.component';

@Component({
  selector: 'app-activate-user-popup',
  templateUrl: './activate-user-popup.component.html',
  styleUrls: ['./activate-user-popup.component.scss']
})
export class ActivateUserPopupComponent implements OnInit {
  
  companies !: Company[];
  departments !: Department[];
  pendingUserData !: PendingUser;
  codeCompanies!: CodeCompany[];
  languages !: Language[];
  license = License;
  enumKeys: any = [];
  form !: UntypedFormGroup;
  public isLoadingTiles : boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PendingUserListComponent>,
    public companyService: CompanyService,
    public departmentService: DepartmentService,
    public languageService: LanguageService,
    public userService: UserService,
    public formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private syncService: SyncService,
    @Inject(MAT_DIALOG_DATA) public data: PendingUser 
  ) { 
    this.enumKeys = Object.keys(this.license).filter(f => !isNaN(Number(f)));
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.pendingUserData = this.data;
    this.loadCompanies();
    this.loadCodeCompanies();
    this.loadLangaues();
    this.loadFormData();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private loadFormData(){
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.pendingUserData.id),
      name: new UntypedFormControl(this.pendingUserData.companyname),
      email: new UntypedFormControl(this.pendingUserData.email, [Validators.required, Validators.email]),
      password: new UntypedFormControl(this.pendingUserData.password),
      hashedPassword: new UntypedFormControl(this.pendingUserData.hashedPassword),
      mobile: new UntypedFormControl(this.pendingUserData.mobile),
      microBuildId : new UntypedFormControl(this.pendingUserData.microBuildId),
      codeCompanyId: new UntypedFormControl(this.pendingUserData.codeCompanyId, [Validators.required]),
      languageId: new UntypedFormControl(this.pendingUserData.languageId, [Validators.required]),
      license: new UntypedFormControl(this.pendingUserData.license, [Validators.required]),
      isActive: new UntypedFormControl(this.pendingUserData.isActive),
      companyId: new UntypedFormControl(this.pendingUserData.companyId, [Validators.required]),
      departmentId: new UntypedFormControl(this.pendingUserData.departmentId),
      companyname: new UntypedFormControl(this.pendingUserData.companyname),
      address: new UntypedFormControl(this.pendingUserData.address),
      postNo: new UntypedFormControl(this.pendingUserData.postNo),
      city: new UntypedFormControl(this.pendingUserData.city),
      mobilePrefix: new UntypedFormControl(this.pendingUserData.mobilePrefix),
      createdDate: new UntypedFormControl(this.pendingUserData.createdDate),
    });
  }
  private loadCompanies() {
    this.companyService.getAllCompanies().subscribe((data:Company[]) => this.companies = data)
  }

  private loadDepartmentsByCompany(CompanyId: string) {
    if (CompanyId) {
      this.departmentService.getDepartments(CompanyId).subscribe((data : Department[]) =>  this.departments = data)
    }
  }

  private loadCodeCompanies() {
    this.companyService.getAllCodeCompanies().subscribe((data:CodeCompany[]) => this.codeCompanies = data);
  }

  private loadLangaues() {
    this.languageService.getLanguages().subscribe((data:Language[]) => this.languages = data);
  }

  onchangeCompany(user: any) {
    let selectedCompanyId = user.value;
    this.loadDepartmentsByCompany(selectedCompanyId);
  }

  onClickSubmit(){
    //if (this.form.valid) {
      var formData: User = new User();
      formData.id = this.pendingUserData.id;
      formData.name = this.pendingUserData.name;
      formData.email = this.pendingUserData.email;
      formData.mobilePrefix = this.pendingUserData.mobilePrefix;
      formData.mobile = this.pendingUserData.mobile;
      formData.microBuildId = this.pendingUserData.microBuildId;
      formData.codeCompanyId = this.form.get('codeCompanyId')?.value;
      formData.languageId = this.form.get('languageId')?.value;
      formData.license = this.form.get('license')?.value;
      formData.isActive = this.pendingUserData.isActive;
      formData.companyId = this.form.get('companyId')?.value;
      formData.departmentId = this.form.get('departmentId')?.value;
      this.userService.createUser(formData).subscribe({
        next: (data: any) => {
          this.dialogRef.close(true);
          this.notificationService.showSuccess(this.translate.instant("common.approved"), this.translate.instant("modules.pending_user"));
          
          let mailchimpFormData = new MailChimpUser();
          mailchimpFormData.firstName = data.name.split(' ').slice(0, -1).join(' ');
          mailchimpFormData.lastName = data.name.split(' ').slice(-1).join(' ');
          mailchimpFormData.email = data.email;
          this.openAddMailchimpPopup(mailchimpFormData);
        }
      });
    // }
  }

  openAddMailchimpPopup(item: any): void {
    const dialogRef = this.dialog.open(AddToMailchimpPopupComponent, {
      width: '650px',
      data: item,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The form dialog was closed');
    });
  }

}
