import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'MBBackOffice';

  pageHeader: string = '';

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'no', 'se']);
    translate.setDefaultLang('no');

    this.translate.use('no');
  }

  setValue(): any {
    this.pageHeader = this.translate.instant('Welcome');
  }

  initializeMarkers() {
    _('mbe_grid.labels.company_users_grid.SuperUser');
    _('mbe_grid.labels.company_users_grid.ProducerAdmin');
    _('mbe_grid.labels.company_users_grid.User');
  }
}
