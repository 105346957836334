import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { User, UserCount, UserInfoTile, UserTwoFactor } from '../../shared/models/User.model';
import { StatusMessageService } from './status-message.service';
import { MailChimpUser } from 'src/app/shared/models/mail-chimp-user.model';
import { TranslateService } from '@ngx-translate/core';
import { ProgressProjectUserType } from 'src/app/shared/models/progress-project-user-type.model';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private NotificationService: NotificationService,
    private translate: TranslateService,
  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(message || this.translate.instant("status_message.common.server_error"));
  }

  public getUserCounts(): Observable<UserCount> {
    return this.httpService.getObject<UserCount>("users/count").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_users_count_message_fail"))));
  }

  public getAllUsers(): Observable<User> {
    return this.httpService.getObject<User>("users").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_users_message_fail"))));
  }

  public createUser(newUser: User): Observable<User> {
    return this.httpService.sendObjects("users/add", newUser).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.create_user_message_fail"))));
  }

  public AddUserToMailChimp(mailChimpData: MailChimpUser): Observable<boolean> {
    return this.httpService.sendObjects("users/mailchimp", mailChimpData).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.add_users_to_mailchimp_message_fail"))));
  }

  public updateUser(user: User): Observable<User> {
    return this.httpService.updateObjects("users/update/mbeuser", user).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.create_user_message_fail"))))
  }

  public updateTwoFactor(req: UserTwoFactor): Observable<UserTwoFactor> {
    return this.httpService.updateObjects("users/update/twofactor", req).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.update_user_twofactor_message_fail"))));
  }

  public getUserInfoTileDetails(userId: string): Observable<UserInfoTile> {
    return this.httpService.getObject<UserInfoTile>("users/" + userId).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_user_message_fail"))));
  }

  public getProgressProjectUserTypeByUserId(userId: string): Observable<ProgressProjectUserType> {
    return this.httpService.getObject<ProgressProjectUserType>("mbp/projects/projectTypeCountByUserId/" + userId).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public resetPassword(req: any) {
    return this.httpService.updateObjects("users/password/reset", req).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public getAllUsersByCompany(companyId: string): Observable<User> {
    return this.httpService.getObject<User>(`users/company/${companyId}`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_users_message_fail"))));
  }

  public getAllUsersByDepartment(companyId: string, departmentId: string): Observable<User> {
    return this.httpService.getObject<User>(`users/company/${companyId}/department/${departmentId}`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_users_message_fail"))));
  }
}
