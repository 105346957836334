<div mat-dialog-title id="formPopupHeader">
  <div class="formPopupHeader-left">
    <h1>
      <b>{{ "common.users" | translate }}</b>
    </h1>
  </div>
  <div class="formPopupHeader-right">
      <span>
          <mat-icon (click)="onClose()">close</mat-icon>
      </span>
  </div>
</div>
<div mat-dialog-content>
  <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
  <mbe-grid id="companyUserList" class="full-page-grid" [columns]="companyUserGridColumns" [objects]="companyUserList"
    (cellSelected)="onCompanyUsersCellSelected($event)" (itemsSelected)="onCompanyUsersItemsSelected($event)">
  </mbe-grid>
</div>