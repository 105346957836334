import { Injectable } from '@angular/core';
import {
  NavigateByType,
  PageNavigationGuide,
} from 'src/app/shared/models/page-navigation-guide.model';

@Injectable({
  providedIn: 'root',
})
export class DataContextService {
  readonly DATE_TIME_FORMAT = 'DD.MM.YYYY HH.mm';
  readonly DATE_FORMAT = 'DD.MM.YYYY';

  constructor() {}

  navigationBy!: NavigateByType;

  userId: string = '';
  companyId: string = '';
  departmentId: string = '';

  isOpenSearch: boolean = false;
  isOpenTopFilter: boolean = false;
  isDashboardPage: boolean = false;
  originPageDataForReport!: {
    originPage: NavigateByType;
    originPageId: string;
    parentId: string;
  };

  get pageNavigationguide(): PageNavigationGuide {
    return JSON.parse(localStorage.getItem('pageNavigationguide') || '{}');
  }

  set pageNavigationguide(env: PageNavigationGuide) {
    localStorage.setItem('pageNavigationguide', JSON.stringify(env));
  }

  organizationNumber: string = '';
}
