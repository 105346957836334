import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { NavigateByType, PageNavigationGuide } from 'src/app/shared/models/page-navigation-guide.model';
import { HttpService } from './http.service';
import { StatusMessageService } from './status-message.service';

import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PageNavigationGuideService {

  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService,
  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(error || this.translate.instant("status_message.common.server_error"));
  }

  public getPageNavigationGuideForUser(type:NavigateByType,userId:string): Observable<PageNavigationGuide> {
    return this.httpService.getObject<PageNavigationGuide>(`navigationGuide/user/${type}/${userId}`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_project_count_message_fail"))));
  }

  public getPageNavigationGuideForCompany(type:NavigateByType,companyId:string): Observable<PageNavigationGuide> {
    return this.httpService.getObject<PageNavigationGuide>(`navigationGuide/company/${type}/${companyId}/`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_project_count_message_fail"))));
  }

  public getPageNavigationGuideForDepartment(type:NavigateByType,companyId:string,departmentId:string): Observable<PageNavigationGuide> {
    return this.httpService.getObject<PageNavigationGuide>(`navigationGuide/department/${type}/${companyId}/${departmentId}`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_project_count_message_fail"))));
  }
}

