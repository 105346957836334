import { Component, OnInit } from '@angular/core';
import {
  ColumnDataType,
  SortDirection,
} from 'src/app/shared/enums/column-data-type.enum';
import { ExcelExportHelperService } from '../../services/excel-export-helper.service';
import { QueryService } from '../../services/query.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-query-page',
  templateUrl: './query-page.component.html',
  styleUrls: ['./query-page.component.scss'],
})
export class QueryPageComponent implements OnInit {
  pageHeader: string = this.translate.instant('modules.query');
  isLoadingTiles: boolean = false;
  queryResult: any[] = [];
  queryGridResult: any[] = [];
  mbeGrid: any;
  maxDateForDatePicker: Date = new Date();
  fromDate: any;
  toDate: any;
  selectedTab: string = '';
  managementQueryResult: any[] = [];
  managementQueryGridResult: any[] = [];
  progressQueryResult: any[] = [];
  progressQueryGridResult: any[] = [];

  constructor(
    private queryService: QueryService,
    private excelExportHelperService: ExcelExportHelperService,
    public datepipe: DatePipe,
    private translate: TranslateService
  ) {}

  gridColumns = [
    {
      key: 'company',
      header: this.translate.instant('common.company'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'department',
      header: this.translate.instant('common.department'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'user',
      header: this.translate.instant('common.username'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'email',
      header: this.translate.instant('common.email'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'adminWriteProjectCount',
      header: this.translate.instant('query.grid.admin_write_projects'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'isActive',
      header: this.translate.instant('common.active'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '10%',
      },
    },
    {
      key: 'date',
      header: this.translate.instant('common.created_date'),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        width: '20%',
      },
      originalKey: 'date',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
  ];

  managementGridColums = [
    {
      key: 'companies',
      header: this.translate.instant('common.companies'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'name',
      header: this.translate.instant('common.project_name'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'number',
      header: this.translate.instant('common.project_no'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'createdDate',
      header: this.translate.instant('common.created_date'),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        width: '20%',
      },
      originalKey: 'createdDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'doorCount',
      header: this.translate.instant('common.door_count'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'nameOfControl',
      header: this.translate.instant('query.grid.name_of_control'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'instancesCount',
      header: this.translate.instant('query.grid.number_of_instances'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'instanceDoorCount',
      header: this.translate.instant('query.grid.sum_of_instance_door_count'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'activatedDate',
      header: this.translate.instant('query.grid.activated_date'),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        width: '20%',
      },
      originalKey: 'activatedDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'adminUserList',
      header: this.translate.instant('query.grid.admin_list'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
  ];

  progressGridColumns = [
    {
      key: 'projectName',
      header: this.translate.instant('common.project_name'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'projectNumber',
      header: this.translate.instant('common.project_no'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
    {
      key: 'startDate',
      header: this.translate.instant('query.grid.start_date'),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        width: '20%',
      },
      originalKey: 'startDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'endDate',
      header: this.translate.instant('query.grid.end_date'),
      type: ColumnDataType.DATETIME,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        width: '20%',
      },
      originalKey: 'endDate',
      formatterFn: (val: any) => {
        return this.datepipe.transform(val, 'YYYY-MM-dd');
      },
    },
    {
      key: 'isActive',
      header: this.translate.instant('common.active'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '10%',
      },
    },
    {
      key: 'doorCount',
      header: this.translate.instant('common.door_count'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '10%',
      },
    },
    {
      key: 'adminNames',
      header: this.translate.instant('common.username'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'text-overflow': 'ellipsis',
        'word-wrap': 'break-word',
        overflow: 'hidden',
        'max-width': '260px',
        width: '20%',
      },
    },
    {
      key: 'adminCompany',
      header: this.translate.instant('common.company'),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
      },
    },
  ];

  ngOnInit(): void {}

  private async assignData(data: any) {
    this.queryResult = data;
    this.queryGridResult = data.map((s: any) => {
      s['isActive'] = s.isActive ? 'Yes' : 'No';
      return {
        selected: false,
        object: s,
      };
    });

    this.mbeGrid = document.querySelector('mbe-grid#queryList');
  }

  private async assignManagementQueryGridData(data: any) {
    this.managementQueryResult = data;
    this.managementQueryGridResult = data.map((s: any) => {
      return {
        selected: false,
        object: s,
      };
    });

    this.mbeGrid = document.querySelector('mbe-grid#queryList');
  }

  private async assignProgressProjectData(data: any) {
    this.progressQueryResult = data;
    this.progressQueryGridResult = data.map((s: any) => {
      s['isActive'] = s.isActive ? 'Yes' : 'No';
      return {
        selected: false,
        object: s,
      };
    });

    this.mbeGrid = document.querySelector('mbe-grid#queryList');
  }

  onExcelExport() {
    if (this.enableEngineeringUserGrid()) {
      this.excelExportHelperService.exportToExcel(this.queryResult);
    } else if (this.enableManagementProjectGrid()) {
      this.excelExportHelperService.exportManagementProjectToExcel(
        this.managementQueryResult
      );
    } else if (this.enableProgressProjectGrid()) {
      this.excelExportHelperService.exportProgressProjectToExcel(
        this.progressQueryResult
      );
    }
  }

  clearExistingData() {
    this.queryResult = [];
    this.queryGridResult = [];
    this.managementQueryGridResult = [];
    this.managementQueryResult = [];
    this.progressQueryGridResult = [];
    this.progressQueryResult = [];
    this.selectedTab = '';
  }

  onUserAddedQuery() {
    this.clearExistingData();
    (this.selectedTab = this.translate.instant('query.types.user_added')),
      this.queryService
        .getUserAddedQuery(
          moment(this.fromDate).format('MM/DD/YYYY'),
          moment(this.toDate).format('MM/DD/YYYY'),
          1
        )
        .subscribe((data) => this.assignData(data));
  }

  onUserDisabledQuery() {
    this.clearExistingData();
    (this.selectedTab = this.translate.instant('query.types.user_disabled')),
      this.queryService
        .getUserAddedQuery(
          moment(this.fromDate).format('MM/DD/YYYY'),
          moment(this.toDate).format('MM/DD/YYYY'),
          4
        )
        .subscribe((data) => this.assignData(data));
  }

  onUserEnabledQuery() {
    this.clearExistingData();
    (this.selectedTab = this.translate.instant('query.types.user_enabled')),
      this.queryService
        .getUserAddedQuery(
          moment(this.fromDate).format('MM/DD/YYYY'),
          moment(this.toDate).format('MM/DD/YYYY'),
          3
        )
        .subscribe((data) => this.assignData(data));
  }

  onManagementQuery() {
    this.clearExistingData();
    (this.selectedTab = this.translate.instant(
      'query.types.management_projects'
    )),
      this.queryService
        .getManagementProjectQuery(
          moment(this.fromDate).format('MM/DD/YYYY'),
          moment(this.toDate).format('MM/DD/YYYY')
        )
        .subscribe((data) => this.assignManagementQueryGridData(data));
  }

  onProgressProjectQuery() {
    this.clearExistingData();
    this.selectedTab = this.translate.instant('query.types.progress_projects');
    this.queryService
      .getProgressProjectQuery(
        moment(this.fromDate).format('MM/DD/YYYY'),
        moment(this.toDate).format('MM/DD/YYYY')
      )
      .subscribe((data) => this.assignProgressProjectData(data));
  }

  gridEnabled(): boolean {
    return (
      this.queryResult.length > 0 ||
      this.managementQueryResult.length > 0 ||
      this.progressQueryResult.length > 0
    );
  }

  enableManagementProjectGrid(): boolean {
    return this.managementQueryResult.length > 0;
  }

  enableEngineeringUserGrid(): boolean {
    return this.queryResult.length > 0;
  }

  enableProgressProjectGrid(): boolean {
    return this.progressQueryResult.length > 0;
  }
}
