  <div mat-dialog-title id="listPopupHeader">
    <div class="formPopupHeader-left">
      <h1><b>{{title}}</b></h1>
  </div>
  <div class="formPopupHeader-right">
  </div>
  </div>
  <div mat-dialog-content class="confirm-popup-body">
    <div class="mat-form-popup-container">
      <h3>{{message}}</h3>
    </div>
    <div style="display: flex; justify-content: flex-end; width: 100%;">
      <button mat-raised-button class="mat-confirm-btn" [mat-dialog-close]="true">{{ "form_popups.buttons.confrim" | translate }}</button>
      <button mat-raised-button class="mat-confirm-btn" [mat-dialog-close]="false">{{ "form_popups.buttons.close" | translate }}</button>
  </div>
  </div>