import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserListComponent } from 'src/app/core/modules/user/user-list/user-list.component';
import { ReportValueChangePopupData } from '../../models/popup-detail.model';

@Component({
  selector: 'app-report-value-change-popup',
  templateUrl: './report-value-change-popup.component.html',
  styleUrls: ['./report-value-change-popup.component.scss'],
})
export class ReportValueChangePopupComponent implements OnInit {
  title: string = '';

  constructor(
    public dialogRef: MatDialogRef<UserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportValueChangePopupData
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.title = this.data.title;
  }
}
