export class ListPopupData {
    title: string;
    content: ListPopupDataContent[];

    constructor() {
        this.title = "";
        this.content = [];
    }
}

export class ListPopupDataContent {
    label: string;
    value: string;
    link?: string;
    constructor() {
        this.label = "";
        this.value = "";
    }
}

export class ConfirmPopupData{
    title : string;
    message : string;
    constructor() {
        this.title = "";
        this.message = "";
    }
}

export class ReportValueChangePopupData {
    title: string;
    content: any[];

    constructor() {
        this.title = "";
        this.content = [];
    }
}