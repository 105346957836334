import { Injectable } from '@angular/core';
import { NavigateByType } from 'src/app/shared/models/page-navigation-guide.model';
import { DataContextService } from './data-context.service';

@Injectable({
  providedIn: 'root',
})
export class TopFilterService {
  constructor(private dataContext: DataContextService) {}

  openTopFilter() {
    this.dataContext.isOpenTopFilter = true;
  }

  closeTopFilter() {
    this.dataContext.isOpenTopFilter = false;
  }

  enableFilterOption() {
    this.dataContext.isDashboardPage = true;
    this.closeTopFilter();
  }

  hideFilterOption() {
    this.dataContext.isDashboardPage = false;
    this.closeTopFilter();
  }

  getReportFilterOrigin() {
    return this.dataContext.originPageDataForReport;
  }

  setReportFilterOrigin(originPage: NavigateByType, originPageId: string, parentId: string = '') {
    this.dataContext.originPageDataForReport = {
      originPage: originPage,
      originPageId: originPageId,
      parentId: parentId
    };
  }
}
