export class ProjectCount {
  public count: number;

  constructor() {
    this.count = 0;
  }
}

export class Project{
  address: String;
  author: String;
  buildingName: String;
  centralFile: String;
  changedDate: String;
  clientName: String;
  createdBy: String;
  createdByUserId: String;
  createdDate: String;
  createdDateTime: String;
  engProjectName: String;
  id: String;
  issueDate: String;
  mbeProjectId: String;
  name: String;
  number: String;
  organizationDescription: String;
  organizationName: String;
  revitId: String;
  status: String;
  usersInProject: UsersInProject[];
  /**
   *
   */
  constructor() {
    this.address = "";
    this.author = "";
    this.buildingName = "";
    this.centralFile = "";
    this.changedDate = "";
    this.clientName = "";
    this.createdBy = "";
    this.createdByUserId = "";
    this.createdDate = "";
    this.createdDateTime = "";
    this.engProjectName = "";
    this.id = "";
    this.issueDate = "";
    this.mbeProjectId = "";
    this.name = "";
    this.number = "";
    this.organizationDescription = "";
    this.organizationName = "";
    this.revitId = "";
    this.status= "";
    this.usersInProject = [];
  }
}

export class UsersInProject{
  userId: String;
  constructor(){
    this.userId = "";
  }
}

export class ProjectUsers{
  public id: string;
  public name: string;
  public email: string;

  /**
   *
   */
  constructor() {
    this.name = "";
    this.email = "";
    this.id = "";
  }
}