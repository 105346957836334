import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyListComponent } from 'src/app/core/modules/company/company-list/company-list.component';
import { CompanyService } from 'src/app/core/services/company.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { Company } from '../../models/company.model';

@Component({
  selector: 'app-update-company-popup',
  templateUrl: './update-company-popup.component.html',
  styleUrls: ['./update-company-popup.component.scss']
})
export class UpdateCompanyPopupComponent implements OnInit {

  companyData !: Company;
  form !: UntypedFormGroup;
  public isLoadingTiles : boolean = true;

  constructor(
    public dialogRef: MatDialogRef<CompanyListComponent>,
    public formBuilder: UntypedFormBuilder,
    public companyService: CompanyService,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private router: Router,
    private syncService: SyncService,
    @Inject(MAT_DIALOG_DATA) public data: Company
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.companyData = this.data;
    this.loadFormData();
  }

  loadFormData(){
    this.form = this.formBuilder.group({
      id: new UntypedFormControl(this.companyData.id),
      name: new UntypedFormControl(this.companyData.name),
      adress1: new UntypedFormControl(this.companyData.adress1),
      adress2: new UntypedFormControl(this.companyData.adress2),
      city: new UntypedFormControl(this.companyData.city),
      postNo: new UntypedFormControl(this.companyData.postNo),
      phone1: new UntypedFormControl(this.companyData.phone1)
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onClickSubmit(){
    if (this.form.valid) {
      var formData: Company = new Company();
      formData.id = this.companyData.id;
      formData.name = this.form.get('name')?.value;
      formData.adress1 = this.form.get('adress1')?.value;
      formData.adress2 = this.form.get('adress2')?.value;
      formData.city = this.form.get('city')?.value;
      formData.phone1 = this.form.get('phone1')?.value;
      formData.postNo = this.form.get('postNo')?.value;
      formData.companyLogo = this.companyData.companyLogo;
      formData.departments = this.companyData.departments;
      formData.users = this.companyData.users;
      formData.isActive = this.companyData.isActive;
      formData.createdDate = this.companyData.createdDate;
      formData.changedDate = new Date();
      this.companyService.updateCompany(formData).subscribe({
        next: (data: any) => {
          this.notificationService.showSuccess(this.translate.instant("common.updated"), this.translate.instant("modules.company"));
          this.syncService.SyncCompany().subscribe({
            next: (data: any) => {
              this.router.navigate(['./company/company-list'])
              .then(() => {
                window.location.reload();
              });
            }
          });
          this.dialogRef.close();
        }
      });
     }
  }
}
