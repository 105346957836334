<mat-card class="common-dashboard-card progress-card" [ngClass]="{'inactive-tile': inactive === true}">
    <mat-card-title class="title">{{title}}</mat-card-title>
    <mat-card-subtitle class="subtitle-wrapper">
        <div class="subtitle">
            {{subTitle}}
        </div>
        <div class="count">
            {{count}}
        </div>
    </mat-card-subtitle>
    <mat-card-content class="content-wrapper">
        <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
        <div class="info" *ngFor="let progressItem of progressList">
            <div class="info-content-row">
                <span class="info-text">{{progressItem.text}}</span>
                <span class="info-count">{{progressItem.count}}</span>
            </div>
            <mat-progress-bar mode="determinate" value="{{progressItem.count/count * 100}}" class="progress-bar">
            </mat-progress-bar>
        </div>
    </mat-card-content>
    <mat-card-actions class="action-wrapper" align="end">
        <a href="{{actionLink}}" [ngStyle]="{'pointer-events': (disabled == true) ? 'none' : 'auto' }">
            {{ "common.more_details" | translate }}
            <span class="material-icons">
                chevron_right
            </span>
        </a>
    </mat-card-actions>
</mat-card>