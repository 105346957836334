<mat-toolbar id="topHeader" class="top-header">
  <mat-toolbar-row>
    <button mat-icon-button (click)="sidenav.toggle()" id="menu-icon">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="menu-icon-logo-wrap">
      <span>
        <a href="/">
          <img class="logo" src="../../../../assets/images/header-logo.png" style="height: 28px;" />
        </a>
      </span>
    </div>
    <span class="menu-spacer"></span>
    <div id="menu-items">
      <a mat-button class="mat-button-toolbar">
        <mat-icon>people</mat-icon>
      </a>
      <a [routerLink]="['sync/logs']" mat-button>
        <mat-icon matTooltip="{{ 'sync_logs.page_header' | translate}}">sync</mat-icon>
      </a>
      <a [routerLink]="['query']" mat-button>
        <mat-icon matTooltip="{{ 'query.page_header' | translate}}">description</mat-icon>
      </a>
      <a mat-button>
        <mat-icon>domain</mat-icon>
      </a>
      <a mat-button>
        <mat-icon>list</mat-icon>
      </a>
      <a *ngIf="dataContext.isDashboardPage" mat-button (click)="openFilter()">
        <mat-icon matTooltip="{{ 'filter.top_filter' | translate}}">filter_alt</mat-icon>
      </a>
      <a (click)="logout()" mat-button matTooltip="{{ 'common.logout' | translate}}">
        <mat-icon>output</mat-icon>
      </a>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav>
    <mat-nav-list>
      <a (click)="sidenav.toggle()" mat-list-item>Close<mat-icon>close</mat-icon></a>
      <a mat-list-item>
        <mat-icon>people</mat-icon>
      </a>
      <a [routerLink]="['sync/logs']" mat-button>
        <mat-icon matTooltip="{{ 'sync_logs.page_header' | translate}}">sync</mat-icon>
      </a>
      <a mat-list-item>
        <mat-icon>domain</mat-icon>
      </a>
      <a mat-list-item>
        <mat-icon>list</mat-icon>
      </a>
      <a *ngIf="dataContext.isDashboardPage" mat-button (click)="openFilter()">
        <mat-icon>filter_alt</mat-icon>
      </a>
      <a (click)="logout()" mat-list-item>
        <mat-icon matTooltip="{{ 'common.logout' | translate}}">output</mat-icon>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content fxFlexFill>
    <app-content></app-content>
  </mat-sidenav-content>
</mat-sidenav-container>