import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PendingUserListComponent } from 'src/app/core/modules/pending-user/pending-user-list/pending-user-list.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserService } from 'src/app/core/services/user.service';
import { MailChimpUser } from '../../models/mail-chimp-user.model';

@Component({
  selector: 'app-add-to-mailchimp-popup',
  templateUrl: './add-to-mailchimp-popup.component.html',
  styleUrls: ['./add-to-mailchimp-popup.component.scss']
})
export class AddToMailchimpPopupComponent implements OnInit {
  userData !: MailChimpUser;
  form !: UntypedFormGroup;
  public isLoadingTiles : boolean = true;

  constructor(
    public dialogRef: MatDialogRef<PendingUserListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MailChimpUser,
    public formBuilder: UntypedFormBuilder,
    public userService: UserService,
    private notificationService: NotificationService,
    private translate: TranslateService,
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.userData = this.data;
    this.form = this.formBuilder.group({
      firstName: new UntypedFormControl(this.userData.firstName),
      lastName: new UntypedFormControl(this.userData.lastName),
      email: new UntypedFormControl(this.userData.email),
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onClickSubmit(){
    if (this.form.valid) {
      var formData: MailChimpUser = new MailChimpUser();
      formData.firstName = this.form.get('firstName')?.value;
      formData.lastName = this.form.get('lastName')?.value;
      formData.email = this.form.get('email')?.value;
      
      this.userService.AddUserToMailChimp(formData).subscribe({
          next : (result : boolean) => {this.notificationService.showSuccess(this.translate.instant("common.success"), this.translate.instant("form_popups.buttons.add_to_mailchimp")); this.dialogRef.close()}
      });
      
    }
  }

}
