<div mat-dialog-title id="listPopupHeader">
    <div fxLayout="row wrap">
      <div fxFlex="75%" fxLayoutAlign="start">
        <h1>
          <b>{{ "common.users" | translate }}</b>
        </h1>
      </div>
      <div fxFlex="25%" fxLayoutAlign="end">
        <span><mat-icon (click)="onClose()">close</mat-icon></span>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
    <mbe-grid
      id="companyUserList"
      class="full-page-grid"
      data-test="company-dept-grid"
      [columns]="userGridColumns"
      [actions]="userGridActions"
      [objects]="userList"
    >
    </mbe-grid>
  </div>
