import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company, CompanyCount } from '../../shared/models/company.model';
import { CodeCompany } from 'src/app/shared/models/code-company.model';
import { StatusMessageService } from './status-message.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyDashboard } from 'src/app/shared/models/company-dashboard.model';
import { EngineeringProjectTile } from 'src/app/shared/models/engineering-project-tile.model';
import { ProgressProjectUserType } from 'src/app/shared/models/progress-project-user-type.model';
import { CompanyDocument } from 'src/app/shared/models/company-document.model';
import { TripleTexCustomer } from 'src/app/shared/models/tripletex-customer.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService,
  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(error || this.translate.instant("status_message.common.server_error"));
  }

  public getCompanyCounts(): Observable<CompanyCount> {
    return this.httpService.getObject<CompanyCount>("company/count").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companycount_message_fail"))));
  }

  public getAllCompanies(): Observable<Company[]> {
    return this.httpService.getObject<Company[]>("companies").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companies_message_fail"))));
  }

  public getAllCodeCompanies(): Observable<CodeCompany[]> {
    return this.httpService.getObject<CodeCompany[]>("company/codecompanies").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companies_message_fail"))));
  }

  public getCompanyDashboard(companyId: string): Observable<CompanyDashboard> {
    return this.httpService.getObject<CompanyDashboard>(`company/dashboard/${companyId}`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companies_message_fail"))));
  }

  public updateCompany(company: Company): Observable<Company> {
    return this.httpService.updateObjects<Company>("company/mbeupdate", company).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.update_company_message_fail"))));
  }

  getEnginerringProjectsInfo(companyId: string): Observable<EngineeringProjectTile> {
    return this.httpService.getObject<EngineeringProjectTile>(`company/${companyId}/mbe/projects`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_companies_message_fail"))));
  }

  public getProgressProjectUserTypeByCompanyId(companyId: string): Observable<ProgressProjectUserType> {
    return this.httpService.getObject<ProgressProjectUserType>("mbp/projects/projectTypeCountByCompanyId/" + companyId).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.user_service.load_progress_project_user_type_tile_fail"))));
  }

  public createCompany(company: Company): Observable<Company> {
    return this.httpService.sendObjects<Company>("company/create", company).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.common.server_error"))));
  }

  public getAllCompanyDocuments(companyId: string): Observable<CompanyDocument[]> {
    return this.httpService.getObject<CompanyDocument[]>(`company/${companyId}/document`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.load_company_documents_message_fail"))));
  }

  public createCompanyDocument(companyDocument: FormData): Observable<CompanyDocument> {
    return this.httpService.sendObjects("company/document", companyDocument).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.create_company_document_company_message_fail"))));
  }

  public deleteCompanyDocument(documentId: string): Observable<boolean> {
    return this.httpService.deleteObject<boolean>(`company/${documentId}/document`).
    pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.delete_company_document_company_message_fail"))));
  }

  public downloadCompanyDocument(documentId: any) {
      return this.httpService.getFileOutput(`company/document/${documentId}/download`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.common.download_company_document_message_fail"))));
  }
  
  public createTripleTexCustomer(model:TripleTexCustomer): Observable<TripleTexCustomer> {
    return this.httpService.sendObjects("company/tripletex/add", model).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.company_service.create_company_document_company_message_fail"))));
  }
}
