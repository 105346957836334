import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProgressProjectListComponent } from 'src/app/core/modules/progress/progress-project-list/progress-project-list.component';
import { ColumnDataType, SortDirection } from '../../enums/column-data-type.enum';
import { MBPTradeCompanyUser } from '../../models/mbprogress-company.mode';

@Component({
  selector: 'app-progress-trade-company-user-popup',
  templateUrl: './progress-trade-company-user-popup.component.html',
  styleUrls: ['./progress-trade-company-user-popup.component.scss']
})
export class ProgressTradeCompanyUserPopupComponent implements OnInit {

  companyUsers: any;
  companyUserList: any;
  isLoadingTiles: boolean = false;
  mbeGrid: any;
  userId!: string;
  selectedUsersCell: any;

  readonly tradeCompanyUserGridColumns = [
    {
      key: 'name', 
      header: this.translate.instant("common.name"),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
        color: '#007bff',
        cursor: 'pointer'
      }
    },
    {
      key: 'email', 
      header: this.translate.instant("common.email"),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        width: '20%',
        color: '#007bff',
        cursor: 'pointer'
      }
    },
    {
      key: 'isAdmin', 
      header: this.translate.instant("common.is_admin"),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '80%'
      },
    },
    {
      key: 'isProjectLeader', 
      header: this.translate.instant("common.is_project_leader"),
      type: ColumnDataType.STRINGNUMBER,
      sort: { enable: true, direction: SortDirection.NONE },
      tdStyle: {
        'text-align': 'left',
        'width': '80%'
      },
    }
  ];

  constructor(private translate: TranslateService,
    public dialogRef: MatDialogRef<ProgressProjectListComponent>,
    private router: Router,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.assignData();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private async assignData() {
    this.companyUsers = this.data;
    this.companyUserList = this.companyUsers.map((s: any) => {
      s['isAdmin'] = s.isAdmin ? this.translate.instant('common.yes') : this.translate.instant('common.no');
      s['isProjectLeader'] = s.isProjectLeader ? this.translate.instant('common.yes') : this.translate.instant('common.no');
      return {
        selected: false,
        object: s,
      };
    });
    this.mbeGrid = document.querySelector("mbe-grid#tradeCompanyUserList");
    await this.mbeGrid["updateDataAsync"](this.companyUserList);
  }

  onCompanyUsersCellSelected(event: any) {
    this.selectedUsersCell = event.detail;
  }

  onCompanyUsersItemsSelected(event: any) {
    const user = event.detail[0].object as MBPTradeCompanyUser;
    switch (this.selectedUsersCell.key) {
      case "name": {
        this.onClose();
        this.router.navigate([`/user-dashboard/${user.mbeUserId}`]);
        break;
      }
      case "email": {
        location.href = `mailto:${user.email}`;
        this.selectedUsersCell = "";
        break;
      }
    }
  }

}
