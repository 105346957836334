import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tile-without-progress',
  templateUrl: './tile-without-progress.component.html',
  styleUrls: ['./tile-without-progress.component.scss'],
})
export class TileWithoutProgressComponent implements OnInit {
  @Input() title: string = '';
  @Input() actionLink: string = '';
  @Input() infoList?: any[];
  @Input() logList?: any[];
  @Input() logHeaderOne?: string;
  @Input() logHeaderTwo?: string;
  @Input() isPopupTile: boolean = false;
  @Input() openListPopup: any;
  @Input() subTitle?: string;
  @Input() count?: string | number;
  @Input() makeHeightAuto?: boolean = false;
  @Input() inactive?:boolean = false;
  @Input() disabled?:boolean = false;
  public isLoadingTiles: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
