import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyDashboardComponent } from 'src/app/core/modules/company/company-dashboard/company-dashboard.component';
import { Department } from '../../models/department.model';

@Component({
  selector: 'app-department-list-popup',
  templateUrl: './department-list-popup.component.html',
  styleUrls: ['./department-list-popup.component.scss']
})
export class DepartmentListPopupComponent implements OnInit {
  departments: any;
  departmentList: any;
  isLoadingTiles : boolean = false;
  mbeGrid : any;
  companyId!: string;

  readonly departmentGridColumns = [
    {
      key: 'name', 
      header: this.translate.instant("common.department"),
      type: 2,
      sort: { enable: true,direction: 1 },
      tdStyle: {
        'text-align': 'left',
        'width': '80%'
      },
    }
  ];

  readonly departmentGridActions = [
    {
      icon: "fa-solid fa-eye",
      class: "btn-grid-row-normal -grid-action-btn -icon-only",
      text: "",
      callback: ((item: { object: Department }) => {
        this.ngZone.run(() => this.viewDeparmentInfo(item));
      }).bind(this),
      title: this.translate.instant("mbe_grid.actions.view_department"),
    },];

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CompanyDashboardComponent>,
    private router: Router,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.assignData() ;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private async assignData() {
    this.companyId = this.data.id;
    this.departments = this.data.departments;
    this.departmentList = this.departments.map((s: any) => {
      return {
        selected: false,
        object: s
      };});
    this.mbeGrid = document.querySelector("mbe-grid#companyDeptList");
    await this.mbeGrid["updateDataAsync"](this.departmentList);
  }

  viewDeparmentInfo(item: any) {
    this.router.navigate([`/company/${this.companyId}/department/${item.object.id}`]);
    this.onClose();
  }
}
