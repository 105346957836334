<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.reset_user_password" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="form" (ngSubmit) = "onClickSubmit()">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.email" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-label class="mat-form-popup-label"><strong>{{ userData.email }}</strong></mat-label>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.password" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="password" formControlName="password" 
                    placeholder='{{ "form_popups.labels.password" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" [disabled]="form.invalid" mat-raised-button class="mat-form-save-btn">{{ "form_popups.buttons.reset" | translate }}</button>
            </div>
        </div>
    </form>
</div>

