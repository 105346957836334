import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyDashboardComponent } from 'src/app/core/modules/company/company-dashboard/company-dashboard.component';
import { User } from '../../models/User.model';

@Component({
  selector: 'app-user-list-popup',
  templateUrl: './user-list-popup.component.html',
  styleUrls: ['./user-list-popup.component.scss']
})
export class UserListPopupComponent implements OnInit {
  users: any;
  userList: any;
  public isLoadingTiles : boolean = false;
  mbeGrid : any;

  readonly userGridColumns = [
    {
      key: 'name', 
      header: this.translate.instant("common.name"),
      type: 2,
      sort: { enable: true,direction: 1 },
      tdStyle: {
        'text-align': 'left',
        'width': '30%'
      },
    },
    {
      key: 'email', 
      header: this.translate.instant("common.email"),
      type: 2,
      sort: { enable: true,direction: 1 },
      tdStyle: {
        'text-align': 'left',
        'width': '30%'
      },
    },
    {
      key: 'phone', 
      header: this.translate.instant("common.phone"),
      type: 2,
      sort: { enable: true,direction: 1 },
      tdStyle: {
        'text-align': 'left',
        'width': '30%'
      },
    }
  ];

  readonly userGridActions = [
    {
      icon: "fa-solid fa-eye",
      class: "btn-grid-row-normal -grid-action-btn -icon-only",
      text: "",
      callback: ((item: { object: User }) => {
        this.ngZone.run(() => this.viewUserInfo(item));
      }).bind(this),
      title: this.translate.instant("mbe_grid.actions.view_user"),
    }];

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<CompanyDashboardComponent>,
    private router: Router,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.assignData() ;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  private async assignData() {
    this.users = this.data;
    this.userList = this.users.map((s: any) => {
      return {
        selected: false,
        object: s
      };
    });
    this.mbeGrid = document.querySelector("mbe-grid#companyUserList");
    await this.mbeGrid["updateDataAsync"](this.userList);
  }

  viewUserInfo(item: any) {
    this.router.navigate(['/user-dashboard', item.object.userId]);
    this.onClose();
  }
}
