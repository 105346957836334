import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StatusMessageService } from './status-message.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectCount, Project, ProjectUsers } from '../../shared/models/mbr-project.model';

@Injectable({
  providedIn: 'root'
})
export class RevitService {
  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService,

  ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(error || this.translate.instant("status_message.common.server_error"));
  }

  public getProjectCounts(): Observable<ProjectCount> {
    return this.httpService.getObject<ProjectCount>("mbr/projects/count").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_project_count_message_fail"))));
  }

  public getRevitProjects(): Observable<Project> {
    return this.httpService.getObject<Project>("mbr/projects").
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_projects_message_fail"))));
  }

  public getRevitProjectUsers(projectId: String): Observable<ProjectUsers> {
    return this.httpService.getObject<ProjectUsers>(`mbr/project/${projectId}/users`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_projects_message_fail"))));
  }

  public updateProject(project: Project): Observable<boolean> {
    return this.httpService.sendObjects<Project>('mbr/project/update', project).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_projects_message_fail"))));
  }

  public removeProjectAndData(revitProjectId: String): Observable<boolean> {
    return this.httpService.deleteObject<any>(`mbr/project/${revitProjectId}/revit`).
      pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.project_service.load_projects_message_fail"))));
  }
}
