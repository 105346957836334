export class IfcProject {
  public createdBy: string;
  public id: string;
  public ifcLastSyncDate: Date;
  public ifcLastSyncUser: string;
  public mbeProjectCreatedDate: Date;
  public mbeProjectId: string;
  public mbeProjectName: string;

  constructor() {
    this.createdBy = '';
    this.id = '';
    this.ifcLastSyncDate = new Date();
    this.ifcLastSyncUser = '';
    this.mbeProjectCreatedDate = new Date();
    this.mbeProjectId = '';
    this.mbeProjectName = '';
  }
}
