<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.edit_revit_project_details" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="modifyProjectForm">

        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.eng_project_id" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput formControlName="mbeProjectId" placeholder='{{ "form_popups.placeholders.enter_eng_project_id" | translate }}'>
                </mat-form-field>
            </div>
        </div>

        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.central_file_location" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput formControlName="centralFile" placeholder='{{ "form_popups.placeholders.enter_central_file_location" | translate }}'>
                </mat-form-field>
            </div>
        </div>

        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" [disabled]="modifyProjectForm.invalid" (click)="updateProject(modifyProjectForm.value)" mat-raised-button class="mat-form-save-btn">{{ "form_popups.buttons.save" | translate }}</button>
            </div>
        </div>

    </form>
</div>