<app-content-header [pageHeader]="pageHeader"></app-content-header>

<div class="app-container report">
  <div class="container">
    <div class="horizontal-centered">
      <mat-card flex-gt-sm class="gridCard">
        <mat-card-title class="gridCard-title">
          <b>{{ "query.types.user_activity" | translate }}</b>
          <button mat-button (click)="onExcelExport()">
            {{ "query.export_to_excel" | translate }}
          </button>
        </mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
        <mat-card-content class="gridCard-content">
          <div>
            <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
            <mbe-grid
              id="queryList"
              class="full-page-grid"
              data-test="queryList"
              [columns]="COLUMNS"
              [objects]="queryGridResult"
              [rowSelectionMode]="'single'"
            >
            </mbe-grid>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
