<div mat-dialog-title id="formPopupHeader">
  <div class="formPopupHeader-left">
    <h1><b>{{ "common.departments" | translate }}</b></h1>
  </div>
  <div class="formPopupHeader-right">
      <span>
          <mat-icon (click)="onClose()">close</mat-icon>
      </span>
  </div>
</div>
<div mat-dialog-content>
  <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
  <mbe-grid
    id="companyDeptList"
    class="full-page-grid"
    data-test="company-dept-grid"
    [columns]="departmentGridColumns"
    [actions]="departmentGridActions"
    [objects]="departmentList"
  >
  </mbe-grid>
</div>
