import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.scss']
})
export class SearchSectionComponent implements OnInit {

  @Output() closeSearchSection = new EventEmitter();
  constructor( public translate: TranslateService) { }

  ngOnInit(): void {
  }

  closeSearch(){
    this.closeSearchSection.emit();
  }

}
