import { LanguageEnum } from './language.model';

export class User {
  public id: string;
  public name: string;
  public email: string;
  public mobile: string;
  public microBuildId: number;
  public languageId: LanguageEnum;
  public license: number;
  public imageUrl: string;
  public isActive: boolean;
  public companyId: string;
  public departmentId: string;
  public visibleOnlyToCompany: boolean;
  public isSelected: boolean;
  public codeCompanyId: string;
  public isTwoFactorEnabled: boolean;
  public isMobileVerified: boolean;
  public authenticatorKey: boolean;
  public mobilePrefix: string;
  public companyName: string;
  public departmentName: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.email = '';
    this.mobile = '';
    this.microBuildId = 0;
    this.languageId = LanguageEnum.Norsk;
    this.license = 0;
    this.imageUrl = '';
    this.isActive = true;
    this.companyId = '';
    this.departmentId = '';
    this.visibleOnlyToCompany = true;
    this.isSelected = true;
    this.codeCompanyId = '';
    this.isTwoFactorEnabled = false;
    this.isMobileVerified = false;
    this.authenticatorKey = false;
    this.mobilePrefix = '';
    this.companyName = '';
    this.departmentName = '';
  }
}

export class UserCount {
  public totalUserCount: number;
  public activeUserCount: number;
  public twoFactorActivatedUserCount: number;
  public mailChimpUserCount: number;

  constructor() {
    this.totalUserCount = 0;
    this.activeUserCount = 0;
    this.twoFactorActivatedUserCount = 0;
    this.mailChimpUserCount = 0;
  }
}

export class UserTwoFactor {
  public isTwoFactorEnabled: boolean;
  public userId: string;

  constructor() {
    this.isTwoFactorEnabled = false;
    this.userId = '';
  }
}

export class UserInfoTile {
  constructor() {
    this.id = '';
    this.name = '';
    this.email = '';
    this.mobilePrefix = '';
    this.mobile = '';
    this.address = '';
    this.companyId = '';
    this.companyName = '';
    this.departmentId = '';
    this.departmentName = '';
    this.codeCompanyId = '';
    this.codeCompanyName = '';
  }

  public id: string;
  public name: string;
  public email: string;
  public mobilePrefix: string;
  public mobile: string;
  public address: string;
  public companyId: string;
  public companyName: string;
  public departmentId: string;
  public departmentName: string;
  public codeCompanyId: string;
  public codeCompanyName: string;
}

export class CompanyUser {
  userId: string;
  role: number;
  name: string;
  email: string;
  phone: string;

  constructor() {
    this.userId = '';
    this.role = 0;
    this.name = '';
    this.email = '';
    this.phone = '';
  }
}
