<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.update_company" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="form" (ngSubmit) = "onClickSubmit()">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.name" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="name" formControlName="name" placeholder='{{ "form_popups.placeholders.enter_name" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address1" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="adress1" formControlName="adress1" placeholder='{{ "form_popups.placeholders.enter_address1" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address2" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="adress2" formControlName="adress2" placeholder='{{ "form_popups.placeholders.enter_address2" | translate }}'>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.city" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="city" formControlName="city" placeholder='{{ "form_popups.placeholders.enter_city" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.post_no" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="postNo" formControlName="postNo" placeholder='{{ "form_popups.placeholders.enter_post_no" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.phone" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="phone1" formControlName="phone1" placeholder='{{ "form_popups.placeholders.enter_phone" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" type="submit" [disabled]="form.invalid" mat-raised-button class="mat-form-save-btn">{{ "form_popups.buttons.update" | translate }}</button>
            </div>
        </div>
    </form>
</div>