import { Department } from "./department.model";
import { User } from "./User.model";

export class Company {
    id:String;
    name: string;
    adress1: string;
    adress2: string;
    postNo: string;
    city: string;
    phone1:string;
    companyLogo: string;
    departments: Department[];
    users: User[];
    isActive:boolean;
    DepartmentCount: number;
    UserCount: number;
    createdDate: Date;
    changedDate: Date;
    tripleTexId!:number;

    constructor() {
        this.id="";
        this.name = "";
        this.adress1 = "";
        this.adress2 = "";
        this.postNo = "";
        this.city = "";
        this.phone1 = "";
        this.companyLogo = "";
        this.departments = [];
        this.users = [];
        this.isActive = false;
        this.DepartmentCount = 0;
        this.UserCount = 0;
        this.createdDate = new Date();
        this.changedDate = new Date();
    }
}

export class CompanyCount {
    public totalCompanyCount: number;
    constructor() {
        this.totalCompanyCount = 0;
    }
}