<div *ngIf="isLoading && !isLoadingTiles" class="overlay">
    <div class="container">
      <div class="item item-1"></div>
      <div class="item item-2"></div>
      <div class="item item-3"></div>
      <div class="item item-4"></div>
    </div>
  </div>

  <div class="holder" *ngIf="isLoading && isLoadingTiles">
    <div class="preloader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>