<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <div *ngIf="department == null; else elseBlock"><h1><b>{{ "form_popups.headers.create_department" | translate }}</b></h1></div>
        <ng-template #elseBlock><h1><b>{{ "form_popups.headers.edit_department" | translate }}</b></h1></ng-template>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="createDepartmentForm">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.name" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="name" matInput placeholder='{{ "form_popups.placeholders.enter_name" | translate }}' [readonly]="department!=null">
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address1" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="adress1" matInput placeholder='{{ "form_popups.placeholders.enter_address1" | translate }}'>
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address2" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="adress2" matInput placeholder='{{ "form_popups.placeholders.enter_address2" | translate }}'>
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.city" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="city"  matInput placeholder='{{ "form_popups.placeholders.enter_city" | translate }}'>
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.post_no" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="postNo"  matInput placeholder='{{ "form_popups.placeholders.enter_post_no" | translate }}'>
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.phone" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="phone1"  matInput placeholder='{{ "form_popups.placeholders.enter_phone" | translate }}'>
            </mat-form-field>
            </div>
        </div>
    
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" [disabled]="createDepartmentForm.invalid" mat-raised-button class="mat-form-save-btn" (click)="createDepartment(createDepartmentForm.value)">{{ "form_popups.buttons.save" | translate }}</button>
            </div>
        </div>
    </form>
</div>



<!-- <div fxLayout="row wrap" id="formPopupHeader">
    <div fxFlex=75% fxLayoutAlign="start">
        <div *ngIf="department == null; else elseBlock"><h1><b>{{ "form_popups.headers.create_department" | translate }}</b></h1></div>
        <ng-template #elseBlock><h1><b>{{ "form_popups.headers.edit_department" | translate }}</b></h1></ng-template>
    </div>
    <div fxFlex=25% fxLayoutAlign="end">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div> -->
<!-- <form class="mat-form-popup" [formGroup]="createDepartmentForm">
    <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>

    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.name" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="name" matInput placeholder='{{ "form_popups.placeholders.enter_name" | translate }}' [readonly]="department!=null">
            </mat-form-field>
        </div>
    </div>
    
    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address1" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="adress1" matInput placeholder='{{ "form_popups.placeholders.enter_address1" | translate }}'>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.address2" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="adress2" matInput placeholder='{{ "form_popups.placeholders.enter_address2" | translate }}'>
            </mat-form-field>
        </div>
    </div>
    
    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.city" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="city"  matInput placeholder='{{ "form_popups.placeholders.enter_city" | translate }}'>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.post_no" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="postNo"  matInput placeholder='{{ "form_popups.placeholders.enter_post_no" | translate }}'>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex=25% fxLayoutAlign="start">
            <mat-label class="mat-form-popup-label">{{ "form_popups.labels.phone" | translate }}</mat-label>
        </div>
        <div fxFlex=75% fxLayoutAlign="start">
            <mat-form-field appearance="outline" class="full-width-field">
                <input formControlName="phone1"  matInput placeholder='{{ "form_popups.placeholders.enter_phone" | translate }}'>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex=75% fxLayoutAlign="start">
        </div>
        <div fxFlex=25% fxLayoutAlign="end">
            <button color="accent" [disabled]="createDepartmentForm.invalid" mat-raised-button class="mat-form-save-btn" (click)="createDepartment(createDepartmentForm.value)">{{ "form_popups.buttons.save" | translate }}</button>
        </div>
    </div>
</form> -->
