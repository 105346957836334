<div mat-dialog-title id="listPopupHeader">
    <div fxLayout="row wrap">
      <div fxFlex="75%" fxLayoutAlign="start">
        <h1>
          <b>{{ "form_popups.headers.trade_company_user" | translate }}</b>
        </h1>
      </div>
      <div fxFlex="25%" fxLayoutAlign="end">
        <span>
          <mat-icon (click)="onClose()">close</mat-icon>
        </span>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>
    <mbe-grid id="tradeCompanyUserList" class="full-page-grid" [columns]="tradeCompanyUserGridColumns" [objects]="companyUserList"
      (cellSelected)="onCompanyUsersCellSelected($event)" (itemsSelected)="onCompanyUsersItemsSelected($event)">
    </mbe-grid>
  </div>