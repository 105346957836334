import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Language } from 'src/app/shared/models/language.model';
import { StatusMessageService } from './status-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private httpService: HttpService,
    private statusMessagingService: StatusMessageService,
    private translate: TranslateService,
    ) { }

  private handleError(error: any, message: string) {
    this.statusMessagingService.error(message);
    return observableThrowError(error || this.translate.instant("status_message.common.server_error"));
  }

  public getLanguages(): Observable<Language[]> {
    return this.httpService.getObject<Language[]>("langauges").
        pipe(catchError(error => this.handleError(error, this.translate.instant("status_message.language_service.load_languages_message_fail"))));
  }
}
