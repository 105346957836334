import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGurdService } from './core/services/auth-gurd/auth-gurd.service';
import { AuthCallbackComponent } from './shared/components/auth-callback/auth-callback.component';

const routes: Routes = [
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./core/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./core/modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./core/modules/company/company.module').then(
        (m) => m.CompanyModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'user-dashboard',
    loadChildren: () =>
      import('./core/modules/user-dashboard/user-dashboard.module').then(
        (m) => m.UserDashboardModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./core/modules/user/user.module').then
        ((m) => m.UserModule
        ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'pending-user',
    loadChildren: () =>
      import('./core/modules/pending-user/pending-user.module').then(
        (m) => m.PendingUserModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'company/:companyId/department',
    loadChildren: () =>
      import('./core/modules/department/department.module').then(
        (m) => m.DepartmentModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'engineering',
    loadChildren: () =>
      import('./core/modules/engineering/engineering.module').then(
        (m) => m.EngineeringModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'progress',
    loadChildren: () =>
      import('./core/modules/progress/progress.module').then(
        (m) => m.ProgressModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'sync',
    loadChildren: () =>
      import('./core/modules/sync/sync.module').then(
        (m) => m.SyncModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'management',
    loadChildren: () =>
      import('./core/modules/management/management.module').then(
        (m) => m.ManagementModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'revit',
    loadChildren: () =>
      import('./core/modules/revit/revit.module').then(
        (m) => m.RevitModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'ifc',
    loadChildren: () =>
      import('./core/modules/ifc/ifc.module').then(
        (m) => m.IfcModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./core/modules/report/report.module').then(
        (m) => m.ReportModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'query',
    loadChildren: () =>
      import('./core/modules/query/query-routing.module').then(
        (m) => m.QueryRoutingModule
      ),
    canActivate: [AuthGurdService],
  },
  {
    path: 'company/:companyId/document',
    loadChildren: () =>
      import('./core/modules/document/document.module').then((m) => m.DocumentModule),
      canActivate: [AuthGurdService],
  },
  {
    path: 'company/:companyId/department/:departmentId/document',
    loadChildren: () =>
      import('./core/modules/document/document.module').then((m) => m.DocumentModule),
      canActivate: [AuthGurdService],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
