<app-content-header [pageHeader]="pageHeader"></app-content-header>
<!-- <app-top-filter *ngIf="filterData" [preSelectedDateRange]="filterData.dateRange" [preSelectedFromDate]="filterData.fromDate"
    [preSelectedToDate]="filterData.toDate" (filterFormSubmittedData)="filterFormSubmit($event)">
</app-top-filter> -->
<app-loader [isLoadingTiles]="isLoadingTiles"></app-loader>

<div class="app-container report">
    <div class="container">
        <div class="horizontal-centered">
            <mat-card flex-gt-sm class="gridCard" >
				<mat-card-title class="gridCard-title">
                    <b>Queries</b>
                    <!-- <b *ngIf="pendingUserList"> {{ filteredUserCount }} </b> -->
                </mat-card-title>
				<mat-card-content class="gridCard-content">
                    <div class="form-field-wrap">
                        <mat-form-field>
                          <mat-label>{{ 'filter.date_range' | translate}}</mat-label>
                          <mat-date-range-input [rangePicker]="picker" [max]="maxDateForDatePicker">
                            <input matStartDate placeholder="{{ 'filter.from_date' | translate}}" [(ngModel)]="fromDate">
                            <input matEndDate placeholder="{{ 'filter.to_date' | translate}}" [(ngModel)]="toDate">
                          </mat-date-range-input>
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-date-range-picker #picker></mat-date-range-picker>
                        </mat-form-field>
                      </div>
                    <div>
                        <button class="query-buttons" mat-raised-button (click)="onUserAddedQuery()">{{ 'query.types.user_added' | translate}}</button>
                        <button class="query-buttons" mat-raised-button (click)="onUserDisabledQuery()">{{ 'query.types.user_disabled' | translate}}</button>
                        <button class="query-buttons" mat-raised-button (click)="onManagementQuery()">{{ 'query.types.management_projects' | translate}}</button>
                        <button class="query-buttons" mat-raised-button (click)="onProgressProjectQuery()">{{ 'query.types.progress_projects' | translate}}</button>
                        <button class="query-buttons" mat-raised-button (click)="onUserEnabledQuery()">{{ 'query.types.user_enabled' | translate}}</button>
                    </div>
				</mat-card-content>
			</mat-card>
        </div>
        <div class="horizontal-centered" *ngIf="gridEnabled()">
            <mat-card flex-gt-sm class="gridCard" >
				<mat-card-title class="gridCard-title">
                    <b>{{ selectedTab }}</b>
                    <button mat-button (click)="onExcelExport()" >{{ 'query.export_to_excel' | translate}}</button>
                </mat-card-title>
				<mat-card-subtitle></mat-card-subtitle>
				<mat-card-content class="gridCard-content">
                    <div *ngIf="enableEngineeringUserGrid()">
                        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
                        <mbe-grid id="queryList" class="full-page-grid" data-test="queryList"
                            [columns]=gridColumns [objects]="queryGridResult" [rowSelectionMode]='"single"'  
                        >
                        </mbe-grid>
                    </div>
                    <div *ngIf="enableManagementProjectGrid()">
                        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
                        <mbe-grid id="queryList" class="full-page-grid" data-test="queryList"
                            [columns]=managementGridColums [objects]="managementQueryGridResult" [rowSelectionMode]='"single"'  
                        >
                        </mbe-grid>
                    </div>
                    <div *ngIf="enableProgressProjectGrid()">
                        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
                        <mbe-grid id="queryList" class="full-page-grid" data-test="queryList"
                            [columns]=progressGridColumns [objects]="progressQueryGridResult" [rowSelectionMode]='"single"'  
                        >
                        </mbe-grid>
                    </div>
				</mat-card-content>
			</mat-card>
        </div>
    </div>
</div>
