<div mat-dialog-title id="formPopupHeader">
    <div class="formPopupHeader-left">
        <h1><b>{{ "form_popups.headers.add_to_mailchimp" | translate }}</b></h1>
    </div>
    <div class="formPopupHeader-right">
        <span>
            <mat-icon (click)="onClose()">close</mat-icon>
        </span>
    </div>
</div>
<div mat-dialog-content>
    <form class="mat-form-popup" [formGroup]="form" (ngSubmit) = "onClickSubmit()">
        <app-loader [isLoadingTiles] = "isLoadingTiles"></app-loader>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.first_name" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="firstName" formControlName="firstName" 
                    placeholder='{{ "form_popups.placeholders.enter_first_name" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.last_name" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="lastName" formControlName="lastName" 
                    placeholder='{{ "form_popups.placeholders.enter_last_name" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div class="mat-form-popup-container-label">
                <mat-label class="mat-form-popup-label">{{ "form_popups.labels.email" | translate }}</mat-label>
            </div>
            <div style="flex: 75%;">
                <mat-form-field appearance="outline" class="full-width-field">
                    <input matInput name="email" formControlName="email"  
                    placeholder='{{ "form_popups.placeholders.enter_email" | translate }}' required>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-form-popup-container">
            <div style="flex: 75%;"></div>
            <div class="mat-form-popup-container-btn">
                <button color="accent" id="mailchimpAddBtn" [disabled]="form.invalid" mat-raised-button class="mat-form-save-btn">{{ "form_popups.buttons.add_to_mailchimp" | translate }}</button>
            </div>
        </div>
    </form>
</div>
